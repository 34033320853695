import { memo } from "react";
import styles from "./homePage.module.css";

function CoverBackground({image}){
    return(
        <img
        id={styles.cover_background}
        src={image}
        alt="">
        </img>
    )    
}

export default memo(CoverBackground)