import { useState } from 'react';
import style from './cms.module.css'
import { useNavigate } from 'react-router-dom'
import { useStateValue } from '../State/state';


export default function LoginCMS(){
    const [userId, setUserid] = useState('');
    const [password, setPassword] = useState('');
    const [state, ] = useStateValue();
    
    function login(e){
        e.preventDefault();
        fetch(state.server_url + '/token/', {
            method: 'POST',
            headers:{
                'Content-Type': 'application/json',
            },
            body:JSON.stringify({
                username: userId,
                password: password,
            }),
        })
        .then((response) => {
            return response.json()
        })
        .then(data => {
            console.log(data)
            localStorage.setItem('access', data.access)
            localStorage.setItem('refresh', data.refresh)
            navigate('..')
        })
    }
    
    const navigate = useNavigate();
    return(
        <div id={style.login}>
            <h1>Are You A REFEer?</h1>
            <h3>PROVE IT</h3>
            <form className={style.column} style={{alignItems:'center'}}
            onSubmit={login}>
                <input type='text' placeholder='User Id' value={userId} onChange={(e)=>setUserid(e.target.value)}></input>
                <input type='password' placeholder='Password' value={password} onChange={(e)=>setPassword(e.target.value)}></input>
                <div className={style.row} style={{ justifyContent: 'space-around', width: '25rem', padding: '2rem' }}>
                    <button type='button' className={style.reset} onClick={() => navigate('/home')}>No I'm not</button>
                    <button type='submit'>Yes, I am</button>
                </div>
            </form>
        </div>
    )
}