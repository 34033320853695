import styles from "./frontPage.module.css";
import { useStateValue } from "../State/state";
import { changeMouseX, changeMouseY } from "../State/action";
import { useNavigate } from "react-router";
import { useState } from "react";

export default function FrontPage() {
  const [state, dispatch] = useStateValue();
  const left = state.mouseX - 10;
  const top = state.mouseY - 10;
  const navigate = useNavigate();
  const [centerClip, setCenterClip] = useState({ x: 0, y: 0 });

  // document.addEventListener('mousemove', e => {
  //     dispatch(changeMouseX(e.pageX));
  //     dispatch(changeMouseY(e.pageY));
  //     // console.log('stuuuc');
  // })
  return (
    <div
      className={styles.containerFrontPage}
      onMouseMove={(e) => {
        dispatch(changeMouseX(e.pageX));
        dispatch(changeMouseY(e.pageY));
      }}
      onMouseLeave={() => {
        dispatch(changeMouseX(-20));
        dispatch(changeMouseY(-20));
      }}
      onClick={() => {
        navigate("/home");
      }}
    >
      <div className={styles.logoFP}>
        <img id={styles.logo1} src={state.images.logo.logo1} alt="" />
      </div>
      <div
        className={styles.mouse}
        style={{ left: left + "px", top: top + "px" }}
      ></div>
      <div
        className={styles.logoBack}
        onMouseMove={(e) => {
          setCenterClip({ x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY });
          document.querySelector(
            `.${styles.mouse}`
          ).style.transform = `scale(1.5)`;
        }}
        onMouseLeave={() => {
          setCenterClip({ x: 0, y: 0 });
          document.querySelector(
            `.${styles.mouse}`
          ).style.transform = `scale(1)`;
        }}
      >
        <div
          style={{
            width: 100 + "px",
            height: 78.75 + "px",
            padding: 15 + "px",
            backgroundImage: `url(${state.images.logo.logo4})`,
            backgroundPosition: `15px 15px`,
            backgroundSize: `calc(100% - 30px)`,
            backgroundRepeat: "no-repeat",
            WebkitClipPath: `circle( 15px at ${centerClip.x}px ${centerClip.y}px)`,
            clipPath: `circle( 15px at ${centerClip.x}px ${centerClip.y}px)`,
          }}
        ></div>
        {/* <img style={{width: 100+'%'}} src={state.images.logo.logo4} alt=''/> */}
      </div>
    </div>
  );
}
