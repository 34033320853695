import style from './cms.module.css'
import {useEffect, useState, useRef} from 'react'
import { Outlet, useNavigate } from 'react-router-dom';
import { useStateValue } from '../State/state';

export default function MainCMS(){
    
    const projectsRef = useRef(null);
    const biosRef = useRef(null);
    const contactsRef = useRef(null);
    const [page, setPage] = useState(projectsRef);
    const [state, ] = useStateValue();

    const navigate = useNavigate()

    function refreshToken() {
        if (localStorage.refresh) {
            fetch(state.server_url + '/token/refresh/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    refresh: localStorage.refresh,
                }),
            })
            .then(response => { 
                if(response.status == 401) navigate('login')
                return response.json() 
            })
            .then((data) => {
                localStorage.access = data.access;
                localStorage.refresh = data.refresh;
            })
        }
    }
    useEffect(() => {
        const minute = 1000 * 60;
        refreshToken();
        setInterval(refreshToken, minute * 2)
    }, [])

    useEffect(()=>{
        page.current.style.borderRight = `0px`;
        page.current.style.color = 'white';        
    });

    function handlePageSelection(e){
        let curr = page.current;
        curr.style.borderRight = `solid 2px white`;
        curr.style.color = `gray`;
        switch(e.target.innerText){
            case 'PROJECTS':
                curr = projectsRef.current;
                setPage(projectsRef);
                navigate('projects');
                break;
            case 'BIOS':
                curr = biosRef.current;
                setPage(biosRef);
                navigate('bio/aledb');
                break;
            case 'CONTACTS':
                curr = contactsRef.current;
                setPage(contactsRef);
                navigate('contacts')
                break;
            default: console.log('error switch');
        }
        
        curr.style.borderRight = `0px`;
        curr.style.color = `white`;
    };

    return (
        <>
        <div className={style.container}>
            <div className={style.side}>
                <div ref={projectsRef} onClick={(e)=>handlePageSelection(e)}>PROJECTS</div>
                <div ref={biosRef} onClick={(e)=>handlePageSelection(e)}>BIOS</div>
                <div ref={contactsRef} onClick={(e)=>handlePageSelection(e)}>CONTACTS</div>
            </div>
            <div className={style.main}>
                {/* from rect router it Outlet it used to render child of parent url */}
                <Outlet/> 
            </div>
        </div>
        </>
    )
}