import { useEffect, useState } from "react";
import { useParams, useNavigate, json} from "react-router-dom"
import style from './cms.module.css'
import { useStateValue } from "../State/state";
import Photos from "./Photos";
import Confirmation from "./Confirmation";

export default function ProjectCMS(){

    const {id} = useParams();
    const [project, setProject] = useState();
    const navigate = useNavigate()
    const [state, ] = useStateValue();
    const [deleting, setDeleting] = useState(false);
    const [saving, setSaving] = useState(false);
    const [tempProject, setTempProject] = useState({});
    const [changing, setChanging] = useState(false);

    

    
    useEffect(()=>{
        const url = state.server_url + '/project/' + id
        fetch(url, {
            headers:{
                'Content-Type':'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('access'),
            } 
        })
        .then((res) => {
            if (res.status === 404) navigate('/404');
            else if (res.status === 401) navigate('../login')
            return res.json();
        })
        .then((data) => {
            setProject(data.project)
            setTempProject(data.project)
        })
    }, [])

    function handleSetAuthors(author) {
        if (tempProject.authors.includes(author)) {
            setTempProject({...tempProject, authors : tempProject.authors.filter(x => x !== author)})
        } 
        else {
            setTempProject({...tempProject, authors : [...tempProject.authors, author]})
        }
        setChanging(true)
    }

    function handleDelete(){
        const url = state.server_url + '/project/' + id;
        fetch(url, {method: 'DELETE', headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('access'),
        }})
        .then((response)=>{
            if(!response.ok) throw new Error('Something went wrong')
            else if (response.status === 401) navigate('../login')
            navigate('../projects')
            return response.json()
        })
        .then((data)=>{
            
        })
        .catch( (e) => {console.log(e)})
    }


    function updateProject(){
        console.log(tempProject)
        fetch(state.server_url + '/project/' + id, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('access'),
            },
            body: JSON.stringify(tempProject)
        })
        .then((response) => {
            if (response.status === 401) navigate('../login')
            return response.json()
        })
        .then((data) => {
            setChanging(false)
            setProject(data.project)
            setTempProject(data.project)
        })
        .catch(e => alert(e))
    }


    function handleReset(){
        setTempProject({...project});
        setChanging(false);
    }


    return(
        <>
        <div  className={style.project}>
                {
                    project ? (
                        <form id={style['project' + id]}
                            onSubmit={(e) => {
                                e.preventDefault();
                                updateProject();
                            }}>
                            <div className={style.row} style={{ alignItems: 'baseline', position:'sticky' ,top:-32, left:0, backgroundColor:"black"}}>
                                <h1 onClick={() => navigate('../projects')} style={{cursor:'pointer'}}>PROJECTS</h1>
                                &ensp;
                                <span>&gt;</span>
                                &ensp;
                                <span>{project.title}</span>
                            </div>
                            <div className={style.row} style={{ alignItems: 'baseline' }}>
                                <label>Title:</label>&ensp;
                                <input required type="text" value={tempProject.title} onChange={(e) => { setTempProject({ ...tempProject, title: e.target.value }); setChanging(true) }}></input>
                            </div>
                            <div className={style.column}>
                                <label>Description:</label>
                                <textarea type='text' name="Project description" value={tempProject.description} onChange={(e) => { setTempProject({ ...tempProject, description: e.target.value }); setChanging(true) }}></textarea>
                            </div>
                            <div className={style.row} style={{ alignItems: 'baseline' }}>
                                <label>Category:</label>
                                <select name="category" id={style['category' + id]} value={tempProject.category} onChange={(e) => { setTempProject({ ...tempProject, category: e.target.value }); setChanging(true) }}>
                                    <option value='c'>Commercial</option>
                                    <option value='e'>Editorial</option>
                                </select>
                            </div>
                            <div className={style.row} style={{ alignItems: 'baseline' }}>
                                <label>Cover Orientation:</label>
                                <select name="cov_orientation" id={style['cov_orientation' + id]} value={tempProject.cover_orientation} onChange={(e) => { setTempProject({ ...tempProject, cover_orientation: e.target.value }); setChanging(true) }}>
                                    <option value='v'>Vertical</option>
                                    <option value='o'>Orizontal</option>
                                </select>
                            </div>
                            <div className={[style.row, style.authors].join(' ')} style={{ alignItems: 'baseline' }}>
                                <label>Authors:</label> &ensp;
                                <div style={
                                    tempProject.authors.includes(1) ?
                                        { border: `solid 1px white`, borderRadius: '4px' } : { border: '0px' }
                                }
                                    onClick={() => handleSetAuthors(1)}
                                >Alessandro De Bellis</div> &ensp;
                                <div style={
                                    tempProject.authors.includes(2) ?
                                        { border: `solid 1px white`, borderRadius: '4px' } : { border: '0px' }
                                }
                                    onClick={() => handleSetAuthors(2)}
                                >Luca Farinet</div> &ensp;
                                <div style={
                                    tempProject.authors.includes(3) ?
                                        { border: `solid 1px white`, borderRadius: '4px' } : { border: '0px' }
                                }
                                    onClick={() => handleSetAuthors(3)}
                                >Matteo montenero</div> &ensp;
                            </div>
                            <div className={style.row}>
                                <div>Position</div>
                                <input type="number" value={tempProject.position} onChange={(e) => { setTempProject({ ...tempProject, position: e.target.value }); setChanging(true) }}/>
                            </div>
                            <div className={style.row} style={{ justifyContent: "flex-end" }}>
                                {
                                    changing ? (<>
                                        <button type='button' className={style.reset} onClick={handleReset}>Reset Changes</button>
                                        <button type='submit'>Save Changes</button>
                                    </>) : ''
                                }
                                <button type="button" onClick={() => setDeleting(true)}>Delete Poject</button>
                            </div>
                        </form>
                    ) : ''
                }
        {
            deleting ? <Confirmation 
            text='Are you shure that you want to delete the current Project?' 
            handleFirst={()=>setDeleting(false)}
            handleSecond={handleDelete}/> : ''
        }
        {tempProject?<Photos projectId = {id} tempProject={tempProject} setTempProject={setTempProject} updateProject={updateProject}/>:''}
        </div>

        
        
        </>
    )
}