import { useParams } from "react-router-dom";
import { useStateValue } from "../State/state";
import styles from "./bioAndInfo.module.css";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { changeMouseX, changeMouseY } from "../State/action";
import useScrollToTop from "./scrollToTop";
import { Link } from "react-router-dom";
import ResponsiveText from "../Components/ResponsiveText";
import useWindowDimensions from "./utils";




export default function Author() {

    const [state, dispatch] = useStateValue();
    const [centerClip, setCenterClip] = useState({ x: 0, y: 0 });
    const [authorInfo, setAuthorInfo] = useState();
    const [projects, setProjects] = useState();
    const navigate = useNavigate();
    const left = state.mouseX - 10;
    const top = state.mouseY - 10;
    const {author} = useParams()
    const {windowWidth, windowHeight} = useWindowDimensions();


    useEffect(()=>{
        const author_info = state.author_info.find(e=>e.path===author)
        setAuthorInfo(author_info)
    },[])

    useEffect(()=>{
        if (authorInfo) {
            (fetch(state.server_url + '/projects/?authors=' + authorInfo.id)
                .then((res) => {
                    return res.json()
                })
                .then((data) => {
                    console.log(data.projects)
                    setProjects(data.projects.filter(e => e.visible === true));
                }).catch((e) => console.log(e)))
        }
      }, [authorInfo]);

    useEffect(() => {
        window.addEventListener("mousemove", customMouseMove);
        return () => window.removeEventListener("mousemove", customMouseMove);
    }, []);

    function customMouseMove(e) {
        dispatch(changeMouseX(e.clientX));
        dispatch(changeMouseY(e.clientY));
    }

    useScrollToTop();

    return (
        <div className={styles.container}

            onMouseLeave={() => {
                dispatch(changeMouseX(-20));
                dispatch(changeMouseY(-20));
            }}
        >
            {authorInfo ? <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                flex: 1,
            }}>
                {/* <div className={styles.author}>{authorInfo.name}</div> */}
                {
                    windowWidth > 380 ? <div className={styles.author}>{authorInfo.name}</div> :
                        <ResponsiveText
                            text={authorInfo.name}
                            maxScalingFactor={1}
                            textStyle={{
                                fontSize: `2rem`,
                                marginBottom: `1rem`,
                            }}
                            updateTriggers= {[windowWidth]}

                        />
                }


                <img className={styles.profile_image} src={authorInfo.picture} />
                <div className={styles.description}>{authorInfo.description}</div>
                <a style={{ margin: '10px 0' }} href={'mailto:' + authorInfo.email}>{authorInfo.email}</a>
                <a style={{ margin: '10px 0' }} href={authorInfo.instagram}>{'@' + authorInfo.instagram.split('/').slice(-1)}</a>
                <div className={styles.header}></div>


            </div> : ''}
            
            <div id={styles.logoBioAndInfo}>
                <img
                    style={{ height: `100%` }}
                    id={styles.logo6}
                    src={state.images.logo.logo1}
                    alt=""
                    onClick={() => navigate("/home")}
                />
            </div>

            <div style={{fontSize:'2rem'}}>
                Projects by {authorInfo ? authorInfo.name.split(' ')[0] : ' '}
            </div>


            <div className={styles.containerProjects}>
            {
                projects ? projects.map((project, projectIdx) => {
                    const data = {
                        projectIdx: projectIdx,
                      };
                    let path
                    if (project.category === 'c'){
                        path = '../commercial/' + project.id
                    } else {
                        path = '../editorial/' + project.id
                    } 
                    return(
                        <Link to={path} state={{ data: data }}>
                            <div>
                                {project.title}
                            </div>
                            <div>

                            </div>

                        </Link>
                    )
                    
                }): ''
            }
            </div>

            <div
                className={styles.mouse}
                style={{ left: left + "px", top: top + "px" }}
            ></div>

            <div
                className={styles.logoBack}
                onMouseMove={(e) => {
                    setCenterClip({ x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY });
                    document.querySelector(
                        `.${styles.mouse}`
                    ).style.transform = `scale(1.5)`;
                }}
                onMouseLeave={() => {
                    setCenterClip({ x: 0, y: 0 });
                    document.querySelector(
                        `.${styles.mouse}`
                    ).style.transform = `scale(1)`;
                }}
                onClick={() => navigate("/home")}
            >
                <div
                    style={{
                        width: 60.59 + "px",
                        height: 48 + "px",
                        padding: 15 + "px",
                        backgroundImage: `url(${state.images.logo.logo4})`,
                        backgroundPosition: `15px 15px`,
                        backgroundSize: `calc(100% - 30px)`,
                        backgroundRepeat: "no-repeat",
                        WebkitClipPath: `circle( 15px at ${centerClip.x}px ${centerClip.y}px)`,
                        clipPath: `circle( 15px at ${centerClip.x}px ${centerClip.y}px)`,
                    }}
                ></div>
            </div>
        </div>

    )
}