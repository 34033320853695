import { useStateValue } from "../State/state";
import { changeMouseX, changeMouseY, setCurrentCategory, setWindowHeight, setWindowWidth } from "../State/action";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import styles from "./homePage.module.css";
import CoverBackground from "./CoverBackground";


export default function HomePage() {

    const [state, dispatch] = useStateValue();
    const [centerClip, setCenterClip] = useState({x:0,y:0});
    const [activeMenù, setActiveMenù] = useState(false);
    // const left = state.mouseX;
    // const top = state.mouseY;
    const [top, setTop] = useState(-20);
    const [left, setLeft] = useState(-20);
    const w = state.window_width;
    const h = state.window_height;
    let visibility = {
        commercial: 'visible',
        editorials: 'hidden',
    };

    const mouse = useRef()

    const navigate = useNavigate();

    // useEffect(()=>{
    //     document.querySelector(`.${styles.button}`).addEventListener('mouseenter', ()=>{
    //         mouse.current.style.backgroundColor = '#e83c30';
    //         mouse.current.style.mixBlendMode = 'difference';
    //     }) 

    //     document.querySelector(`.${styles.button}`).addEventListener('mouseleave', ()=>{
    //         mouse.current.style.backgroundColor = '#c0c0c0';
    //         mouse.current.style.mixBlendMode = 'normal';
    //     })
    // }, [])

    function handleMouseEnter() {
        console.log('enter')
        mouse.current.style.backgroundColor = 'transparent';
        mouse.current.style.mixBlendMode = 'normal';
        mouse.current.style.border = `1px solid #e83c30`;
        mouse.current.style.height = `40px`;
        mouse.current.style.width = `40px`;
    }

    function handleMouseLeave() {
        console.log('exit')
        mouse.current.style.backgroundColor = '#c0c0c0';
        mouse.current.style.mixBlendMode = 'difference';
        mouse.current.style.border = `0px solid #e83c30`;
        mouse.current.style.height = `20px`;
        mouse.current.style.width = `20px`;
    }

    


    function commercialVisibility(){
        visibility.commercial=!visibility.commercial;
    }
    const setWindowDimensions = () => {
        dispatch(setWindowWidth(window.innerWidth));
        dispatch(setWindowHeight(window.innerHeight));
    }

    useEffect(() => {
        setWindowDimensions();
        window.addEventListener('resize', setWindowDimensions);
        return () => {
          window.removeEventListener('resize', setWindowDimensions);
        }
    }, [])

    useEffect(()=>{
        window.addEventListener('mousemove', customMouseMove);
        return ()=>window.removeEventListener('mousemove', customMouseMove);
    },[])

    function customMouseMove(e){
        // dispatch(changeMouseX(e.clientX));
        // dispatch(changeMouseY(e.clientY));
        setTop(e.clientY);
        setLeft(e.clientX);
    }




    return (
        <div
        className={`${styles.flexContainer} ${styles.fullPage}`}
        style={{cursor: 'none', overflow: 'hidden', backgroundColor : `#e2dabe`}}
        onMouseLeave={()=>{
            // dispatch(changeMouseX(-20));
            // dispatch(changeMouseY(-20));
            setTop(-20);
            setLeft(-20);
        }}
        >

            {/* <img 
            // style={
            //     {top: w>890 ? -25 + (top-h/2)/(2*h)  + "%" : -25 + '%', left: w>890 ? -25 + (left-w/2)/(2*w) + "%" : -25 + '%'}
            // }
            id={styles.cover_background}
            src={state.images.logo.logo_black} 
            alt=''
            loading="lazy"
            /> */}

            <CoverBackground image={state.images.logo.logo_black}/>

            {/* <div
            id = {styles.textBackground}
            style = {{
                backgroundImage : `url(${state.images.logo.logo9})`,
                display: w<890 ? 'flex' : 'none'}}
            >
                <div> 
                    REFE <br/>
                    collective of <br/>
                    photographers<br/>
                    based in <span>Turin</span>
                </div>
            </div> */}


            <div className={styles.hamburgerMenu}
            style={{display: activeMenù && w<890 ? 'flex' : 'none'}}>
                <img src={state.images.logo.logo5}/>
                <div className={styles.button} onClick={()=>{navigate('/commercial'); dispatch(setCurrentCategory('commercial'))}} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>COMMERCIAL</div>
                <div className={styles.button} onClick={()=>{navigate('/aboutus')}} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} >ABOUT US</div>
                <div className={styles.button} onClick={()=>{navigate('/editorial'); dispatch(setCurrentCategory('editorial'))}} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>EDITORIALS</div>
                <div className={styles.button} onClick={()=>{navigate('/contacts')}}onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} >CONTACTS</div>
            </div>

            {/* <img style={{width:150+"%", height: 150+"%", objectFit: 'cover', position: "absolute", top: -24 + (top-h/2)/(2.5*h)  + "%" , left: -24 + (left-w/2)/(2.5*w) + "%"}} id='cover_background' src={state.images.logo.logo6_red} alt='' /> */}


               
            <div className={styles.buttonWrapper}>
                <div className={styles.firstAnimation}>
                    <div className={styles.sep}></div>
                    <div className={styles.button} onClick={()=>{dispatch(setCurrentCategory('commercial')) ; navigate('/commercial')}} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>COMMERCIAL</div>
                    <div className={styles.sep}></div>
                    <div className={styles.button} onClick={()=>{navigate('/aboutus')}} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} >ABOUT US</div>
                    <div className={styles.sep}></div>
                    <div className={styles.button} onClick={()=>{dispatch(setCurrentCategory('editorial')); navigate('/editorial')}} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>EDITORIALS</div>
                    <div className={styles.sep}></div>
                    <div className={styles.button} onClick={()=>{navigate('/contacts')}} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} >CONTACTS</div>
                    <div className={styles.sep}></div>
                </div>
                {/*      --------------------------- COPIA PER ANIMAZIONE SCORRIMENTO ORIZZONTALE ----------------------------------------           
                <div className="secondAnimation">
                    <div className="button"><span>{String.fromCharCode(8226)}</span>COMMERCIAL<span>{String.fromCharCode(8226)}</span></div>
                    <div>REFESTUDIO</div>
                    <div className="button"><span>{String.fromCharCode(8226)}</span>BIO-INFO<span>{String.fromCharCode(8226)}</span></div>
                    <div>REFESTUDIO</div>
                    <div className="button"><span>{String.fromCharCode(8226)}</span>EDITORIALS<span>{String.fromCharCode(8226)}</span></div>
                    <div>REFESTUDIO</div>
                    <div className="button"><span>{String.fromCharCode(8226)}</span>CONTACTS<span>{String.fromCharCode(8226)}</span></div>
                    <div>REFESTUDIO</div>
                </div>
                 */}
            </div>

            <div id={styles.logoHomePage} >
                    <img style={{width: 100 + '%', objectFit: 'cover'}} id={styles.logo6} src={state.images.logo.logo1} alt='' />
                    
            </div>

            <div className={styles.hamburger}
            style = {{display: w<890 ? 'flex': 'none'}}
            onClick = {()=>{setActiveMenù(!activeMenù)}}>
                <div style = {{backgroundColor: activeMenù ? 'black' : 'white', borderColor: activeMenù ? 'black' : 'white'}}></div>
                <div style = {{backgroundColor: activeMenù ? 'black' : 'white', borderColor: activeMenù ? 'black' : 'white'}}></div>
                <div style = {{backgroundColor: activeMenù ? 'black' : 'white', borderColor: activeMenù ? 'black' : 'white'}}></div>
            </div>

            <div id={styles.home_footer}>&copy; 2024 Refe Studio - All rights reserved</div>

            <div ref={mouse} className={styles.mouse} style={{ pointerEvents: 'none',left : left -10  + 'px', top: top - 10  + 'px'}}></div> {/* -10 in left e top serve a centrare il mouse con il cerchio del cursore che ha raggio 20px */}
                        
            <div className={styles.logoBack}
                onMouseMove={(e) => {
                    setCenterClip({ x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY });
                    mouse.current.style.transform = `scale(1.5)`;
                }}
                onMouseLeave={() => {
                    setCenterClip({ x: 0, y: 0 });
                    mouse.current.style.transform = `scale(1)`;
                    }}>
                <div style={{
                    width: 60 + 'px',
                    height: 47.25 + 'px',
                    padding: 15 + 'px',
                    backgroundImage: `url(${state.images.logo.logo4})`,
                    backgroundPosition: `15px 15px`,
                    backgroundSize: `calc(100% - 30px)`,
                    backgroundRepeat: "no-repeat",
                    WebkitClipPath: `circle( 15px at ${centerClip.x}px ${centerClip.y}px)`,
                    clipPath: `circle( 15px at ${centerClip.x}px ${centerClip.y}px)`,
                }}></div>
                {/* <img style={{width: 100+'%'}} src={state.images.logo.logo4} alt=''/> */}
            </div>
        
        </div>

  );
}
