import { useRef, useEffect } from "react";

export default function ResponsiveText({text, textClass, textStyle, maxScalingFactor = 1, updateTriggers = []}){

    const textContainerRef = useRef();
    const responsiveTextRef = useRef();

    const textcss = { ...textStyle,
        whiteSpace: `nowrap`,
        width: `max-content`,
        transformOrigin: `bottom left`,
    }


    useEffect(()=>{
        const title_container_size = textContainerRef.current.clientWidth;
        const title_text_size = responsiveTextRef.current.clientWidth;
        const scale_factor = (title_container_size - 50) / title_text_size;
        if (scale_factor < maxScalingFactor)
            responsiveTextRef.current.style.transform = `scale(${scale_factor})`;
        else
            responsiveTextRef.current.style.transform = `scale(${maxScalingFactor})`;
    }, updateTriggers);


    return(
        <div ref = {textContainerRef} style={{width: `100%`}}>
            <div ref = {responsiveTextRef} style={textcss} className={textClass}>
                {text}
            </div>
        </div>
    )
}