import "./App.css";

import { StateProvider} from "./State/state";
import reducer from "./State/reducer";
import initialState from "./State/initialState";
import RouteTree from "./Components/RouteTree";

function App() {
   return (
    <div className="App">
      <StateProvider reducer={reducer} initialState={initialState}>
          <RouteTree />
      </StateProvider>
    </div>
  );
}

export default App;
