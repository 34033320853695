import { useEffect, useState } from "react";
import style from './cms.module.css'
import { json, useNavigate } from "react-router-dom";
import { useStateValue } from "../State/state";
import NewProject from "./NewProject";



export default function ProjectsCMS(){

    const [projects, setProjects] = useState();
    const navigate = useNavigate();
    const [state, ] = useStateValue();
    const [visible, setVisible] = useState(false);

    useEffect(()=>{
        fetch(state.server_url + '/projects/',{
            headers:{
                Authorization: 'Bearer ' + localStorage.getItem('access'),
            }
        })
        .then((res)=>{
            if(res.status === 401) navigate('../login')
            return res.json()
        })
        .then((data)=>{
            setProjects(data.projects);
        }).catch((e)=>console.log(e))
    }, []);

    function newProject(submittinData){
        fetch(state.server_url + '/projects/', {
            method:'POST',
            headers:{
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('access'),
            },
            body: JSON.stringify(submittinData)
        })
        .then(response =>{
            if(!response.ok){
                throw new Error('Something went wrong')
            }
            else if (response.status === 401) navigate('../login')
            return response.json()
        })
        .then((data) =>{
            setVisible(false);
            setProjects([...projects, data.project])
        })
        .catch( (e) => console.log(e))
    }

    return(
        <>
            <div className={style.row} style={{justifyContent:'space-between', alignItems:'baseline'}}>
                <h1>PROJECTS</h1>
                <button id={style['new-project']} onClick={()=>setVisible(!visible)}>{visible ? 'CANCEL':'ADD NEW PROJECT'}</button>
            </div>
            <NewProject visible={visible} newProject={newProject}/>
            <div style={visible ? {marginTop:'2em'}:{}}>
                <h3>Your Project list</h3>
            {
                projects ? projects.map((pj) => {
                    return (
                        <div key={pj.id} className={`${style.projectLinkContainer} ${style.row}`} onClick={()=>navigate(String(pj.id))}>
                            <div className={style.row}>
                                <div>{pj.position}</div>&ensp;
                                <div>{pj.title}</div>
                            </div>                            
                            <div>{pj.category==='c' ? 'COMMERCIAL':'EDITORIAL'}</div>
                        </div>
                    )
                }) : null
            }
            </div>
        </>
    )
}