import React, { useState, useEffect } from "react";
import { useStateValue } from "../State/state";
import { Link } from "react-router-dom";
import {Cloudinary} from '@cloudinary/url-gen'
import {AdvancedImage} from '@cloudinary/react'

import css from "./Project.module.css";

const Project = ({ windowWidth, projectsList, projectIdx }) => {

  const [state, dispatch] = useStateValue();
  const [defaultHeight, setDefaultHeight] = useState(0);
  const [topDivHeight, setTopDivHeight] = useState(0);
  const [photos, setPhotos] = useState();

  const project = projectsList[projectIdx];
  const galleryIndexes = photos;
  // const coverImgUrl = photos[project.photos[0]].url; ale mi ha detto di togliere la cover all'inizio del progetto

  const prevProject = projectsList[projectIdx - 1];
  const nextProject = projectsList[projectIdx + 1];
  const prevData = {
    projectsList: projectsList,
    projectIdx: projectIdx - 1,
  }; 
  const nextData = {
    projectsList: projectsList,
    projectIdx: projectIdx + 1,
  };

  const cld = new Cloudinary({
    cloud:{
        cloudName: 'refestudio',
    }
  })

  useEffect(()=>{
      fetch(state.server_url + '/photos/' + '?project=' + project.id)
      .then((response)=>{
          return response.json()
      })
      .then((data)=>{
          setPhotos(data.photos.filter(e => e.visible === true))
          console.log(data.photos.filter(e => e.visible === true))
      })
      .catch((e)=>(console.log(e)))
  },[projectIdx])

  useEffect(()=>{
    const title_container_size = document.querySelector(`.${css.title}`).clientWidth;
    const title_text_size = document.querySelector(`.${css['text-title']}`).clientWidth;
    const scale_factor = (title_container_size-50)/title_text_size;
    if(scale_factor<3) document.querySelector(`.${css['text-title']}`).style.transform = `scale(${scale_factor})`;
    else document.querySelector(`.${css['text-title']}`).style.transform = `scale(3)`;
  },[windowWidth, projectIdx]);

  // const handleCoverImgLoad = (event) => {
  //   const image = event.target;
  //   if (image.complete) {
  //     const { naturalWidth, naturalHeight } = image;
  //     const scaleRatio = naturalWidth / windowWidth;
  //     let newDefaultHeight = naturalHeight / scaleRatio;
  //     if (newDefaultHeight >= 400) newDefaultHeight = 400;
  //     setDefaultHeight(newDefaultHeight);
  //     setTopDivHeight(newDefaultHeight);
  //   }
  // };

  const handleScroll = () => {
    const newHeight =
      window.scrollY <= defaultHeight ? defaultHeight - window.scrollY : 0;
    setTopDivHeight(newHeight);
  };

  // useEffect(() => {
  //   const image = new Image();
  //   image.src = coverImgUrl;
  //   image.onload = handleCoverImgLoad;

  //   return () => {
  //     image.onload = null;
  //   };
  // }, [projectIdx]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [defaultHeight]);

  let description, autors;


  if(Array.isArray(project.description)){
      description = project.description.map((el, idx) => {
        return <div style={{fontSize : (17-2*idx) + 'px', fontWeight : 500 - 150*idx, margin: `4px 0`}}>{el}</div>
      });
    }
    else {
      description = project.description;
    }
    
    if(Array.isArray(project.authors)) {
      autors =  project.authors.map((el) => {
        return <div>{state.autors[el-1]}</div>
      })

      console.log(autors)
    }
    else {
      autors = '';
    }

  function handleInfoClicked(){
    return console.log('clicked');
  }

  return (
    <div className={css.Projects}>
      {/* <div className={css.cover} style={{ height: `${topDivHeight}px` , display: project.description? "flex":"none"}}>
        <img src={coverImgUrl} alt="cover image" />
      </div> */}
      <div className={css.main} style={{ marginTop: project.description ? `${defaultHeight}px` : `0px` , paddingTop : topDivHeight === 0 ? `4.5rem` : 0}}>
        <div className={css.info}>
          <div className={css.title} style={{ position: topDivHeight === 0 || !project.description ? "fixed" : "relative", top: 0, left: 0}}>
            <div className={css['text-title']}>{project.title}</div>
          </div>
          <div className={css.autors}>{autors}</div>
          <div className={css.description}>{description}</div>
        </div>
        <div className={css.gallery}>
          <div className={css.slideshow}>
            { photos && photos.length>0 ? 
            galleryIndexes.map((photo, idx) => (
              <div key={photo.id} style={{flex: `1 0 100%`, padding: `0 0.5rem`}}>
                <div className={`${css["slideshow-img"]}`}>
                  <AdvancedImage className={css.cloudinary} cldImg={cld.image(photo.cloudinary)} key={photo.id}/>
                </div>
                <div style={{textAlign:"right"}}>&#x1F890;{'   '}{idx+1}/{galleryIndexes.length}{'   '}&#x1F892;</div>
                <div className={css['img-info']} onClick={handleInfoClicked}>
                  <div className={css['img-title']}>{photo.title}</div>
                  <div className={css['img-caption']}>{photo.caption}</div>
                </div>                
                {/* <div className={css['img-caption-cover']}></div> */}
              </div>)) : ''}
          </div>
        </div>
        
      </div>
      <div className={css.navbar}>
        {prevProject != undefined && (
          <Link
            to={`../${prevProject.id}`}
            relative="path"
            state={{ data: prevData }}
          >
            <div>PREVIOUS</div>
          </Link>
        )}
        <Link to={`..`} relative="path">
          <div>ALL</div>
        </Link>
        {nextProject != undefined && (
          <Link
            to={`../${nextProject.id}`}
            relative="path"
            state={{ data: nextData }}
          >
            <div>NEXT</div>
          </Link>
        )}
      </div>
    </div>
  );
};

export default Project;
