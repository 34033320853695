import { useEffect, useRef, useState } from 'react'
import style from './cms.module.css'
import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { useStateValue } from '../State/state'
import { useNavigate } from 'react-router-dom'
import {Cloudinary} from '@cloudinary/url-gen'
import {AdvancedImage} from '@cloudinary/react'
import { ReactComponent as SvgVisibility} from './visibility.svg'
import { ReactComponent as SvgVisibilityOff} from './visibility_off.svg'
import Confirmation from './Confirmation'

export default function Photos(props) {
    const input_title_placeholder = 'Add TITLE to image (if more than one image is been selected the same title is given)'
    const input_caption_placeholder = 'Add CAPTION to image (if more than one image is been selected the same caption is given)'
    const [state, ] = useStateValue(); 
    const [files, setFiles] = useState([]); // images in the input drug and drop
    const [title, setTitle] = useState(''); // new image field
    const [caption , setCaption] = useState(''); // new image field
    const [error, setError] = useState(false); // error shown when save image without choose any image
    const [images, setImages] = useState([]); // images from server
    const [showDetail, setShowDetail] = useState(false); // boolean if detail are shown
    const [detailOf, setDetailOf] = useState(null);  // id of the image for the detail
    const [projects, setProjects] = useState([]); //list of projects to move photo
    const [changing, setChanging] = useState(true); //boolean to control if the photo detail are different from original
    const [confirmation, setConfirmation] = useState(false); //boolean to show the confirmation to delete images
    const [selected, setSetected] = useState([]); //list of photos selected
    const [tempDetail, setTempDetail] = useState({}); //the detail of the photo when changed
    
    const firstRender = useRef(true);

    const cld = new Cloudinary({
        cloud:{
            cloudName: 'refestudio',
        }
    })

    const navigate = useNavigate();

    useEffect(()=>{
        fetch(state.server_url + '/photos/' + '?project=' + props.projectId,{
            headers:{
                Authorization: 'Bearer '+localStorage.getItem('access')
            }
        })
        .then((response)=>{
            if(response.status == 401) navigate('../login')
            return response.json()
        })
        .then((data)=>{
            setImages(data.photos)
        })
        .catch((e)=>(console.log(e)))
    },[])

    const onDrop = useCallback((acceptedFiles , rejectedFiles) => {
        if(acceptedFiles?.length){
            setFiles( prevFiles => [
                ...prevFiles, 
                ...acceptedFiles.map(file => Object.assign(file, { preview: URL.createObjectURL(file)}))
            ])
            setError(false)
        }
        if(rejectedFiles?.length){
            let error = ' !! These files are larger then 1 Mbyte !! ' + '\n'
            rejectedFiles.forEach( x => error = error + x.file.name + '\n');
            alert(error)
        }
    }, [])
    
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ 
        onDrop,
        accept: {
            'image/*':[]
        },
        maxSize: 1024 * 1000
    })

    function handleDeletePreview(image){
        setFiles(files.filter(x => x!==image ))
    }
    function handleSelectImage(image){
        console.log(image.id, 'selected')
    }

    
    
    const addNewImages = () => {
        if(files.length){
            files.forEach(image => {
                const formData = new FormData();
                formData.append('title', title);
                formData.append('caption', caption);
                formData.append('project', props.projectId);
                formData.append('cloudinary', image);

                fetch(state.server_url + '/photos/', {
                    method: 'POST',
                    headers: {
                        Authorization: 'Bearer ' + localStorage.access,
                    },
                   body: formData,
                })
                .then((response) => {
                    if (response.status === 401) navigate('../login');
                    return response.json();
                })
                .then((data) => {
                    setFiles(files.filter(x => x!==image))
                    setImages( prevImages => [...prevImages, data.photo])
                })
                .catch((e) => console.log(e))
            })
        }
        else setError(true)
    }

    function deleteImage(id){
        fetch(state.server_url + '/photo/' + id,{
            method: 'DELETE',
            headers:{
                Authorization: 'Bearer '+localStorage.access
            }
        })
        .then((response)=>{
            if(response.status === 401) navigate('../login');
            if (response.status === 204) {
                setImages(images.filter(x => x.id !== id))
                setConfirmation(false)
                setShowDetail(false)
            }
            return response.json();
        })
        .catch((e)=>console.log(e))
    }

    function changeVisibility(image){
        // const formData = new FormData();
        // formData.append('title', image.title)
        // formData.append('caption', image.caption)
        // formData.append('project', image.project)
        // formData.append('visible', !image.visible)
        const tempImage = {...image, visible: !image.visible}

        fetch(state.server_url + '/photo/' + image.id, {
            method:'POST',
            headers:{
                Authorization: 'Bearer '+localStorage.access,
                'Content-Type':'application/json'
            },
            // body:formData
            body:JSON.stringify(tempImage)
        })
        .then((response)=>{
            if(response.status === 401) navigate('../login')
            return response.json()
        })
        .then((data)=>{
            const newImages = images.map(img =>{
                if(img === image){
                    return data.photo                
                }
                else return img
                })
            setImages(newImages)
        })
        .catch((e)=> console.log(e))
    }
    
    function getProjects(){
        fetch(state.server_url + '/projects/',{
            headers:{
                Authorization: 'Bearer ' + localStorage.getItem('access'),
            }
        })
        .then((res)=>{
            if(res.status === 401) navigate('../login')
            return res.json()
        })
        .then((data)=>{
            setProjects(data.projects);
        }).catch((e)=>console.log(e))
    }

    function updateImage(){
        fetch(state.server_url + '/photo/' + detailOf.id, {
            method:'POST',
            headers:{
                Authorization: 'Bearer '+localStorage.access,
                'Content-Type':'application/json'
            },
            // body:formData
            body:JSON.stringify(tempDetail)
        })
        .then((response)=>{
            if(response.status === 401) navigate('../login')
            return response.json()
        })
        .then((data)=>{
            const newImages = images.map(img =>{
                if(img === detailOf){
                    return data.photo                
                }
                else return img
                })
            setImages(newImages)
            setTempDetail(data.photo)
            setDetailOf(data.photo)
            setChanging(false)
            alert('Image successfully updated!')
        })
        .catch((e)=> console.log(e))
        window.location.reload(false);
        
    }

    function updateProjectCover(image){
        console.log(image.id)
        props.setTempProject(prevTempProject => {return {...prevTempProject , cover: image.id}})
    }
    useEffect(()=>{
        console.log(firstRender.current)
        if(firstRender.current) firstRender.current = false;
        else props.updateProject()        
    },[props.tempProject.cover])

    
        

    return (
        <>
            <form className={style.imageList}
            onSubmit={(e)=>{
                e.preventDefault();
                addNewImages();
            }}>
                <div>IMAGES</div>
                { error ? (<div style={{alignSelf:'center', color:'red', textDecoration:'underline'}}>Add al least one image</div>) : ''}
                <div {...getRootProps({
                    className: style.dragPhoto
                })}>
                    <input name='Project Images' {...getInputProps()} />
                    {
                        isDragActive ?
                            <p>Drop the photos here ...</p> :
                            <p>Drag 'n' drop some photos here, or click to select files</p>
                    }
                </div>
                <ul className={style.row} style={{flexWrap:'wrap', listStyleType:'none', padding:0, margin:0}}>
                    {files.map( image => {
                        return (
                            <li key={image.name}>
                                <button type='button' className={style.deleteIcon} onClick={()=>handleDeletePreview(image)}>X</button>
                                <img src={image.preview} width={100} height={100} />    
                            </li>            
                        )
                    })}
                </ul>
                <div className={style.column}>
                    <div className={style.row} style={{alignItems:'baseline'}}>
                        <label>Title:</label>&ensp;
                        <input type='text' value={title} onChange={e=>setTitle(e.target.value)} name='image-title' placeholder={input_title_placeholder}></input>
                    </div>
                    <div className={style.row} style={{alignItems:'baseline'}}>
                        <label>Caption:</label>&ensp;
                        <textarea name='image-caption' value={caption} onChange={e=>setCaption(e.target.value)} placeholder={input_caption_placeholder}></textarea>
                    </div>
                </div>
                <div className={style.row} style={{justifyContent:'flex-end', margin:'20px 0'}}>
                    <button type='submit'>Save New Images</button>
                </div>
            </form>
            <div style={{margin:'20px 0'}}>Click to each image to see detail...</div>
            <ul className={style.row} style={{ flexWrap: 'wrap', listStyleType: 'none', padding: 0, margin: 0 }}>
                {images.map(image => {
                    return (
                        <li key={image.id} className={style.imageCard} 
                        style={image.id === props.tempProject.cover ? {borderColor:'darkred', overflow:'hidden'}:{}}>
                            <div className={style.row} style={{ justifyContent: 'space-between' }}>
                                <button type='button' className={style.selectIcon} onClick={() => handleSelectImage(image)}></button>
                                <div>{image.position}</div>
                                <button type='button' className={style.visibilityIcon} onClick={()=>changeVisibility(image)}>
                                    {image.visible ? <SvgVisibility fill='white' height={20} width={20} /> : <SvgVisibilityOff fill='white' height={20} width={20} />}
                                </button>
                            </div>
                            <AdvancedImage 
                                cldImg={cld.image(image.cloudinary)} 
                                width={100} height={100}
                                style={{cursor:'pointer'}} 
                                onClick={() => {
                                    getProjects()
                                    setShowDetail(true)
                                    setDetailOf(image)
                                    setTempDetail(image)
                                }}/>
                            <button type='button' 
                                className={style.deleteCard} 
                                onClick={() => { 
                                    setDetailOf(image); 
                                    setConfirmation(true) 
                                }}>DELETE</button>
                            {
                                image.id === props.tempProject.cover ? <button
                                    type='button'
                                    className={style.infoCard}
                                    style={{backgroundColor:'darkred', borderRadius: '0'}}
                                >COVER</button> : <button
                                        type='button'
                                        className={style.infoCard}
                                        onClick={
                                            () => { updateProjectCover(image) }
                                        }>Set As Cover</button>
                            }
                            
                        </li>
                    )
                })}
            </ul>
            {showDetail && detailOf ? (
                <div className={style.imageDetail}>
                    <div className={style.row} style={{width:'90%'}}>
                        <div className={style.column}>
                            <AdvancedImage cldImg={cld.image(detailOf.cloudinary.split('/').slice(-1))} height={300} />
                            <button type='button' 
                                onClick={()=>{
                                    setConfirmation(true);
                                }}>Delete</button>
                        </div>
                        
                        <form className={style.formImageDetail}
                        onSubmit={(e)=>{
                            e.preventDefault()
                            updateImage()
                        }}>
                            <div className={style.backButton} onClick={()=>setShowDetail(false)}>Return Back</div>
                            <div className={style.column}>
                                <div className={style.row} style={{ alignItems: 'baseline' }}>
                                    <label>Title:</label>&ensp;
                                    <input type='text' value={tempDetail.title} onChange={(e)=>{setTempDetail({...tempDetail, title:e.target.value});setChanging(true)}} name='image-title'></input>
                                </div>
                                <div className={style.row} style={{ alignItems: 'baseline' }}>
                                    <label>Caption:</label>&ensp;
                                    <textarea name='image-caption' value={tempDetail.caption} onChange={(e)=>{setTempDetail({...tempDetail, caption:e.target.value});setChanging(true)}}></textarea>
                                </div>
                                <div className={style.row} style={{ alignItems: 'baseline' }}>
                                    <label>Change Project:</label>
                                    <select value={tempDetail.project} onChange={(e)=>{setTempDetail({...tempDetail, project:e.target.value});setChanging(true)}}>
                                        {
                                            projects.map(project => {
                                                console.log(project)
                                                return(
                                                    <option key={project.id} value={project.id}>{project.title}</option>
                                                )
                                            })
                                        }                                        
                                    </select>
                                </div>
                                <div className={style.row}>
                                    <div>Position</div>
                                    <input type="number" value={tempDetail.position} onChange={(e) => { setTempDetail({ ...tempDetail, position: e.target.value }); setChanging(true) }} />
                                </div>                                
                            </div>
                            <div className={style.row} style={{height:'100%', justifyContent: "flex-end", alignItems:'flex-end' }}>
                                    {
                                        changing ? (<>
                                            <button type='button' className={style.reset} onClick={()=>{setTempDetail(detailOf); setChanging(false)}} >Reset Changes</button>
                                            <button type='submit'>Save Changes</button>
                                        </>) : ''
                                    }
                            </div>
                        </form>
                    </div>
                    

                    

                </div>
            ) : ''}
            {
                confirmation ? <Confirmation 
                text = {selected.length ? 'Do you really want to delete ' + selected.length + 'images' : 'Do you really want to delete 1 image'}
                handleFirst = {()=>setConfirmation(false)}
                handleSecond = {()=>deleteImage(detailOf.id)}/> : '' 
            }
            

        </>

    )
}
