export function add(){
    return({type: 'ADD'});
}

export function changeMouseX(mouseX){
    return({type: 'MOUSE-X', mouseX: mouseX});
}

export function changeMouseY(mouseY){
    return({type: 'MOUSE-Y', mouseY: mouseY});
}

export function setWindowHeight(window_height){
    return({type: 'WINDOW_HEIGHT', window_height: window_height});
}

export function setWindowWidth(window_width){
    return({type: 'WINDOW_WIDTH', window_width: window_width});
}

export function setCurrentCategory(currCategory){
    return({type: 'CURRENT_CATEGORY', currCategory: currCategory });
}