import React, { useState, useEffect, useCallback, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useStateValue } from "../State/state";
import { changeMouseX, changeMouseY, setCurrentCategory } from "../State/action";

import Project from "./Project";
import ProjectAlt from "./ProjectAlt";

import useWindowDimensions from "./utils";
import useScrollToTop from "./scrollToTop";

import styles from "./Projects.module.css";

const Projects = ({ category }) => {
  const [state, dispatch] = useStateValue();
  const [categoryName, setCategoryName] = useState();
  const { windowHeight, windowWidth } = useWindowDimensions();
  const [isFirstScrolling, updateFirstScrolling] = useState(true);
  const navigate = useNavigate();
  const [centerClip, setCenterClip] = useState({ x: 0, y: 0 });
  const [projects, setProjects] = useState()
  const left = state.mouseX - 10;
  const top = state.mouseY - 10;

  const mouse = useRef();

  const {projectId} = useParams()

  /////// FETCH PROJECTS FROM SERVER ////////

  // save into projects state

  useEffect(()=>{
    async function fetchData(){
      await fetch(state.server_url + '/projects/?visible=True')
    .then((res)=>{
        return res.json()
    })
    .then((data)=>{
        let prjs = data.projects.filter(e => e.category === category);
        setProjects(prjs);
    }).catch((e)=>console.log(e))
    }
    fetchData();
    
  }, [category]);

  useEffect(()=>{
    category === 'c' ? setCategoryName('commercial') : setCategoryName('editorial') 
  },[])



  function handleChangeCat(cat){
    dispatch(setCurrentCategory(cat));
    navigate('/' + cat);
  }



  function handleMouseEnter() {
    mouse.current.style.backgroundColor = 'transparent';
    mouse.current.style.mixBlendMode = 'normal';
    mouse.current.style.border = `1px solid #e83c30`;
    mouse.current.style.height = `40px`;
    mouse.current.style.width = `40px`;
  }

  function handleMouseLeave() {
    mouse.current.style.backgroundColor = '#c0c0c0';
    mouse.current.style.mixBlendMode = 'difference';
    mouse.current.style.border = `0px solid #e83c30`;
    mouse.current.style.height = `20px`;
    mouse.current.style.width = `20px`;
  }

  useEffect(() => {
    window.addEventListener("mousemove", customMouseMove);
    return () => window.removeEventListener("mousemove", customMouseMove);
  }, []);

  function customMouseMove(e) {
    dispatch(changeMouseX(e.clientX));
    dispatch(changeMouseY(e.clientY));
  }

  useScrollToTop(); //PROBLEMA: questo lo fa scrollare su quando passi da mobile a desktop

  const location = useLocation();
  const data = location.state?.data;
  //const projectsList = data.projectsList;
  const WIDTH_SMALL = 992;

  const scroll = useCallback((node) => {
    if (node) {
      const father = document.querySelector(`.${styles.ProjectAltContainer}`);
      const child = document.querySelector(`#project${projectId}`);
      node.scrollTo({
        top: document.querySelector(`#project${projectId}`).offsetTop,
        behavior: "smooth",
      });
      if (father.scrollTop === child.offsetTop) {
        updateFirstScrolling(false);
      }
    }
  }, []);

  function handleScroll(event) {
    const father = document.querySelector(`.${styles.ProjectAltContainer}`);
    const child = document.querySelector(`#project${projectId}`);
    if (Math.round(father.scrollTop) === Math.round(child.offsetTop)) {
      updateFirstScrolling(false);
    }
    if (!isFirstScrolling) {
      let resto, newIdx, newPath;
      // console.log(windowHeight);
      // console.log(Math.round(father.scrollTop));
      resto = Math.round(father.scrollTop) % windowHeight; //questo è uguale a 0 quando lo scrolling è posizionato su uno dei progetti
      if (resto < 3 || resto > windowHeight - 3) {
        newIdx = father.scrollTop / windowHeight;
        newIdx = Math.round(newIdx);
        newPath = projects[newIdx].id;
        const data = {
          projectIdx: newIdx,
        };
        navigate(`../${categoryName}/${newPath}`, {
          replace: true,
          state: { data: data },
        });
      }
    }
  }

  if (windowWidth < WIDTH_SMALL) {
    let projectIdx = projects?.findIndex((element) => element.id ===  parseInt(projectId))
    return (
      projects ? 
      <Project
        projectsList = {projects}
        windowWidth={windowWidth}
        projectIdx={projectIdx}
      /> : ''
    );
  } else {
    const projectAltList = projects?.map((p, projectId) => (
      <ProjectAlt project={p} idx={p.id} id={projectId} windowWidth={windowWidth}/>
    ));
    return (
      <div className={styles.webProjects}>
        <div className={`${styles["main-container"]}`}>
          {/* <div className={`${styles["logo-container"]}`}>
            <img
              className={styles.logo}
              src={state.images.logo.logo6}
              alt="logo"
            />
          </div> */}
          <aside className={`${styles["aside-left"]}`}>
            <div className={`${styles["navLink-left"]} ${styles.navLink}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={()=>navigate(`../${state.currCategory}`)}>
              <span>PROJECTS</span>
              <span>LIST</span>
            </div>
          </aside>
          {projects?<div
            className={styles.ProjectAltContainer}
            ref={scroll}
            onScroll={handleScroll}
          >
            {projectAltList}
          </div>:''}
          <aside className={`${styles["aside-right"]}`}>
            <div className={`${styles["navLink-right"]} ${styles.navLink}`}>
              <span onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} className={category == 'c' ? styles['category-active'] : ''} onClick={()=>handleChangeCat('commercial')}>COMMERCIAL</span>
              <span onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} className={category == 'e' ? styles['category-active'] : ''} onClick={()=>handleChangeCat('editorial')}>EDITORIAL</span>
              <div className={`${styles["navLink-alt"]}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={()=>navigate(`../${state.currCategory}`)}>
                <span>PROJECTS</span>
                <span>LIST</span>
              </div>
            </div>
          </aside>
          <div id={styles.logoProjects}>
            <img
              style={{ height: `100%` }}
              id={styles.logo6}
              src={state.images.logo.logo4}
              alt=""
              onClick={() => navigate("/home")}
            />
          </div>
          <div
            className={styles.mouse}
            ref={mouse}
            style={{ left: left + "px", top: top + "px" }}
          ></div>
          <div
            className={styles.logoBack}
            onMouseMove={(e) => {
              setCenterClip({
                x: e.nativeEvent.offsetX,
                y: e.nativeEvent.offsetY,
              });
              document.querySelector(
                `.${styles.mouse}`
              ).style.transform = `scale(1.5)`;
            }}
            onMouseLeave={() => {
              setCenterClip({ x: 0, y: 0 });
              document.querySelector(
                `.${styles.mouse}`
              ).style.transform = `scale(1)`;
            }}
            onClick={() => navigate("/home")}
          >
            <div
              style={{
                width: 60.59 + "px",
                height: 48 + "px",
                padding: 15 + "px",
                backgroundImage: `url(${state.images.logo.logo6})`,
                backgroundPosition: `15px 15px`,
                backgroundSize: `calc(100% - 30px)`,
                backgroundRepeat: "no-repeat",
                WebkitClipPath: `circle( 15px at ${centerClip.x}px ${centerClip.y}px)`,
                clipPath: `circle( 15px at ${centerClip.x}px ${centerClip.y}px)`,
              }}
            ></div>
            {/* <img style={{width: 100+'%'}} src={state.images.logo.logo4} alt=''/> */}
          </div>
        </div>
      </div>
    );
  }
};

export default Projects;
