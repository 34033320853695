import { useState } from "react"
import style from './cms.module.css'

export default function(props){
    const [authors, setAuthors] = useState([])
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [category, setCategory] = useState('c')
    const [coverOrientation, setCoverOrientation] = useState('v')
    const [submittingData, setSubmittingData] = useState({})


    // function handleSubmit(){
    //     console.log('submiting:');
    //      setSubmittingData({...submittingData, 
    //          'authors' : authors,
    //          'title' : title, 
    //          'description' : description,
    //          'category' : category,
    //          'cover orientation' : coverOrientation,
    //      })
    //     console.log(submittingData)
    // }

    function handleSetAuthors(author){
        authors.includes(author) ?
        setAuthors(authors.filter(x => x!==author)) : setAuthors([...authors , author ]) 
    }
    return(
        <div style={props.visible ? {display: "flex"}:{display: 'none'}}>
            <form id={style['newProject']}
                style={{ width: '100%' }}
                onSubmit={(event) => {
                    event.preventDefault();
                    setAuthors([])
                    setCategory('c')
                    setCoverOrientation('v')
                    setDescription('')
                    setTitle('')
                    props.newProject({
                        authors: authors,
                        title: title,
                        description: description,
                        category: category,
                        cover_orientation: coverOrientation,
                    })
                }}>
                    <div className={style.row} style={{alignItems:'baseline'}}>
                        <label>Title:</label>&ensp;
                        <input required type='text' value={title} onChange={e =>{setTitle(e.target.value)}} placeholder="Insert the Project title"></input>
                    </div>
                    <div className={style.column}>
                        <label>Description:</label>
                        <textarea type='text' name="Project description" value={description} onChange={e =>{setDescription(e.target.value)}} placeholder="Add a description to your Project"></textarea>
                    </div>
                    <div className={style.row} style={{alignItems:'baseline'}}>
                        <label>Category:</label>
                        <select name="category" id={style['newCategory']} onChange={e =>{setCategory(e.target.value)}}>
                            <option value='c' >Commercial</option>
                            <option value='e' >Editorial</option>
                        </select>
                    </div>
                    <div className={style.row} style={{alignItems:'baseline'}} onChange={e =>{setCoverOrientation(e.target.value)}}>
                        <label>Cover Orientation:</label>
                        <select name="cov_orientation" id={style['new_cov_orientation']}>
                            <option value='v' >Vertical</option>
                            <option value='o' >Orizontal</option>
                        </select>
                    </div>
                    <div className={`${style['row']} ${style['authors']}`} style={{alignItems:'baseline'} }>
                        <label>Authors:</label> &ensp;
                        <div style={
                            authors.includes(1) ?
                            {border:`solid 1px white`, borderRadius: '4px'}:{border:'0px'}
                        }
                        onClick={()=>handleSetAuthors(1)}>Alessandro De Bellis</div> &ensp;
                        <div style={
                            authors.includes(2) ?
                            {border:`solid 1px white`, borderRadius: '4px'}:{border:'0px'}
                        }onClick={()=>handleSetAuthors(2)}>Luca Farinet</div> &ensp;
                        <div style={
                            authors.includes(3) ?
                            {border:`solid 1px white`, borderRadius: '4px'}:{border:'0px'}
                        }
                        onClick={()=>handleSetAuthors(3)}>Matteo Montenero</div> &ensp;
                    </div>
                    <div className={style.row} style={{ justifyContent: "flex-end" }}>
                        <button type='submit'>Save</button>
                    </div>
                </form>
        </div>
    )
}