import { useStateValue } from "../State/state";
import styles from "./bioAndInfo.module.css";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { changeMouseX, changeMouseY } from "../State/action";



export default function Contacts() {

    const [state, dispatch] = useStateValue();
    const [centerClip, setCenterClip] = useState({ x: 0, y: 0 });
    const navigate = useNavigate();
    const [handelerStopperMouseListener, setHandelerStopperMouseListener] = useState(true)
    const left = state.mouseX - 10;
    const top = state.mouseY - 10;
    const authors = state.author_info

    useEffect(() => {
        window.addEventListener("mousemove", customMouseMove);
        return () => window.removeEventListener("mousemove", customMouseMove);
    }, [handelerStopperMouseListener]);

    function customMouseMove(e) {      
        if(handelerStopperMouseListener){
            dispatch(changeMouseX(e.clientX));
            dispatch(changeMouseY(e.clientY));
        }
        else{
            dispatch(changeMouseX(-20));
            dispatch(changeMouseY(-20));
        } 
        
    }

    return (
        <div className={styles.container}

            onMouseLeave={() => {
                dispatch(changeMouseX(-20));
                dispatch(changeMouseY(-20));
            }}
        >
            
            <h1>INFO and CONTACTS</h1>
            <img className={styles.contactsIcon} src={state.images.icon.position}/>
            <div
            onMouseEnter={() => {
                setHandelerStopperMouseListener(false)              
            }}
            onMouseLeave={()=>{
                setHandelerStopperMouseListener(true)
            }}
            >
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d18952.043301787282!2d7.7036913010281145!3d45.080964778781876!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47886d01f30ed64b%3A0x669a97768ead6eaf!2sREFEstudio!5e0!3m2!1sit!2sit!4v1703259728955!5m2!1sit!2sit"
                    width="400"
                    height="300"
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                    >
                </iframe>
            </div>
            <a href="https://maps.app.goo.gl/dUJYrR1c36Lu4joX6">Via Mantova, 36, 10153 Torino TO</a>
            <div style={{
                display: 'flex',
                alignItems: 'baseline'
            }}>
                <img className={styles.instagramIcon} src={state.images.icon.instagram}/>
                <a className={styles.instagramLink} href="https://www.instagram.com/refe_studio">refe_studio</a>
            </div>
            <img className={styles.contactsIcon} src={state.images.icon.eye}/>
            <div className={styles.authorsContacts}>
                {
                    authors.map(author=>(
                        <div>
                            <button onClick={()=>navigate('../aboutus/'+author.name)} style={{ margin: '20px 0 10px 0' }}>{author.name}</button>
                            <a style={{ margin: '5px 0' }} href={'mailto:' + author.email}>{author.email}</a>
                            <a style={{ margin: '5px 0' }} href={author.instagram}>{'@' + author.instagram.split('/').slice(-1)}</a>
                            <div style={{ margin: '5px 0' }}>P.IVA {author.partitaiva}</div>
                        </div>
                    ))
                }

            </div>
            <div className={styles.header}></div>

            <div id={styles.footer}>&copy; 2024 Refe Studio - All rights reserved</div>
            




            <div id={styles.logoBioAndInfo}>
                <img
                    style={{ height: `100%` }}
                    id={styles.logo6}
                    src={state.images.logo.logo1}
                    alt=""
                    onClick={() => navigate("/home")}
                />
            </div>

            <div
                className={styles.mouse}
                style={{ left: left + "px", top: top + "px" }}
            ></div>

            <div
                className={styles.logoBack}
                onMouseMove={(e) => {
                    setCenterClip({ x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY });
                    document.querySelector(
                        `.${styles.mouse}`
                    ).style.transform = `scale(1.5)`;
                }}
                onMouseLeave={() => {
                    setCenterClip({ x: 0, y: 0 });
                    document.querySelector(
                        `.${styles.mouse}`
                    ).style.transform = `scale(1)`;
                }}
                onClick={() => navigate("/home")}
            >
                <div
                    style={{
                        width: 60.59 + "px",
                        height: 48 + "px",
                        padding: 15 + "px",
                        backgroundImage: `url(${state.images.logo.logo4})`,
                        backgroundPosition: `15px 15px`,
                        backgroundSize: `calc(100% - 30px)`,
                        backgroundRepeat: "no-repeat",
                        WebkitClipPath: `circle( 15px at ${centerClip.x}px ${centerClip.y}px)`,
                        clipPath: `circle( 15px at ${centerClip.x}px ${centerClip.y}px)`,
                    }}
                ></div>
            </div>
        </div>

    )
}