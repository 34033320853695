import style from './cms.module.css'

export default function Confirmation(props){
    return(
        <div className={style.confirmation}>
            <div className={style.column} style={{alignItems:'center'}}>
                {props.text}
                <div className={style.row} style={{justifyContent:'space-around'}}>
                    <button type='submit' onClick={props.handleFirst}>Cancel</button>
                    <button type='button' onClick={props.handleSecond}>Delete</button>
                </div>
            </div>            
        </div>
    )
}