export default function reducer(state, action){
    switch(action.type) {
        case 'ADD' :
            return {...state, count: state.count + 1}
        case 'MOUSE-X':
            return {...state, mouseX: action.mouseX}
        case 'MOUSE-Y':
            return {...state, mouseY: action.mouseY}
        case 'WINDOW_WIDTH':
            return {...state, window_width: action.window_width}
        case 'WINDOW_HEIGHT':
            return {...state, window_height: action.window_height}
        case 'CURR_CATEGORY': 
            return {...state, currCategory: action.currCategory}
        default : return state;
    }
}