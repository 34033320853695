import React, { useRef } from "react";
import { useState, useEffect } from "react";
import { useStateValue } from "../State/state";
import {Cloudinary} from '@cloudinary/url-gen'
import {AdvancedImage} from '@cloudinary/react'

import styles from "./ProjectAlt.module.css";
import css from "./Projects.module.css";

function ProjectAlt({project, idx, windowWidth}) {
  const [state, ] = useStateValue();
  const [photos, setPhotos] = useState();
  const [imgIdx, setImgIdx] = useState(0);
  const [imgOffset, setImgOffset] = useState([0, 0]);
  const [infoIsShown, setInfoIsShown] = useState(true);
  const [img_pad_left, set_img_pad_left] = useState(`0rem`);

  const cld = new Cloudinary({
      cloud:{
          cloudName: 'refestudio',
      }
  })

  useEffect(()=>{
      fetch(state.server_url + '/photos/' + '?project=' + idx)
      .then((response)=>{
          return response.json()
      })
      .then((data)=>{
          setPhotos(data.photos.filter(e => e.visible === true))
      })
      .catch((e)=>(console.log(e)))
  },[])

  const aside_title_height = useRef()
  

  const changeImage = (step) => {
    if (imgIdx === photos.length - 1 && step > 0) setImgIdx(0);
    else if (imgIdx === 0 && step < 0) setImgIdx(photos.length - 1);
    else setImgIdx((prev) => prev + step);
    
    if(infoIsShown){
      handleInfoShow(true)
    }
  };
  
  function handleInfoShow(istrue){
    const project = document.querySelector(`#project${idx}`);

    const info = project.querySelector(`.${styles.info}`);
    const info_arrow = project.querySelector(`.${styles['aside-info-title']}`);
    const caption = project.querySelector(`.${styles.caption}`);
    const img_caption = project.querySelector(`.${styles['img-caption']}`);
    if(istrue){
      console.log(info_arrow.style.display);
      info.style.display = `none`;
      info_arrow.style.display = `flex`;
      console.log(info_arrow.style.display);
      caption.style.justifyContent = `center`;
      img_caption.style.width = `auto`;
      setInfoIsShown(false);
    }
    else{
      console.log(istrue)
      info.style.display = `flex`;
      info_arrow.style.display = `none`;
      caption.style.justifyContent = 'flex-start';
      img_caption.style.width = `320px`;
      setInfoIsShown(true);
    }
  }
    
  useEffect(() => {
    if (project.title) {
      const title_container_size = document.querySelector(`#titolo-${idx}`).clientWidth;
      const title_text_size = document.querySelector(`#text-title-${idx}`).clientWidth;
      const scale_factor = (title_container_size) / title_text_size;
      if (scale_factor < 3) document.querySelector(`#text-title-${idx}`).style.transform = `scale(${scale_factor})`;
      else document.querySelector(`#text-title-${idx}`).style.transform = `scale(3)`;
    }
  }, []);

  useEffect(() => {
    if(project.title){
      const title_info_container_height = document.querySelector(`.${CSS.escape(styles['aside-title-container-fixed-height'])}`).clientHeight;
      const fixed_height = aside_title_height.current.clientHeight
      const title_info_container_size = document.querySelector(`#aside-title-container-var-size-${idx}`).clientHeight;
      //const scale_height_factor = title_info_container_height/title_info_container_size;
      const scale_height_factor = fixed_height/title_info_container_size;
      if(scale_height_factor < 1) document.querySelector(`#aside-title-container-var-size-${idx}`).style.transform = `scale(${scale_height_factor})`;   //qui c'è un errore!! risolto con le ref  
    }
  },  [windowWidth, infoIsShown]);

  

  useEffect(()=>{
    if(windowWidth >= 992 && windowWidth<=1300 && !infoIsShown){
      set_img_pad_left(`3.7rem`);
    }
    else set_img_pad_left(`0rem`);    
  }, [windowWidth, infoIsShown]);
  



  const imgStyle = {
    top: `${50 + imgOffset[1]}%`,
    left: `${50 + imgOffset[0]}%`,
  };

  function handleMouseEnter2() {
    document.querySelector(`.${css.mouse}`).style.backgroundColor = 'transparent';
    document.querySelector(`.${css.mouse}`).style.mixBlendMode = 'normal';
    document.querySelector(`.${css.mouse}`).style.border = `1px solid #e83c30`;
    document.querySelector(`.${css.mouse}`).style.height = `40px`;
    document.querySelector(`.${css.mouse}`).style.width = `40px`;
  }

  function handleMouseLeave2() {
    document.querySelector(`.${css.mouse}`).style.backgroundColor = '#c0c0c0';
    document.querySelector(`.${css.mouse}`).style.mixBlendMode = 'difference';
    document.querySelector(`.${css.mouse}`).style.border = `0px solid #e83c30`;
    document.querySelector(`.${css.mouse}`).style.height = `20px`;
    document.querySelector(`.${css.mouse}`).style.width = `20px`;
  }

  function handleMouseEnter(lato) {
    const mouse = document.querySelector(`.${css.mouse}`);
    mouse.style.backgroundColor = "transparent";
    mouse.style.borderRadius = `0%`;
    mouse.style.border = `solid #c0c0c0`;
    mouse.style.borderWidth = `0 3px 3px 0`;
    if (lato === "sx") mouse.style.transform = `rotate(135deg)`;
    else mouse.style.transform = `rotate(-45deg)`;
  }

  function handleMouseLeave(lato) {
    const mouse = document.querySelector(`.${css.mouse}`);
    mouse.style.backgroundColor = "#c0c0c0";
    mouse.style.borderRadius = "50%";
    mouse.style.border = `0px`;
    mouse.style.borderWidth = `0`;
    if (lato === "sx") mouse.style.transform = `rotate(0deg)`;
    else mouse.style.transform = `rotate(0deg)`;
  }

  function onDCScroll(event) {
    console.log(event.target.scrollTop);
  }

  let description, authors;

  if (Array.isArray(project.description)) {
    description = project.description.map((el, idx) => {
      return (
        <div
          style={{
            fontSize: 17 - 2 * idx + "px",
            fontWeight: 500 - 150 * idx,
            margin: `4px 0`,
          }}
        >
          {el}
        </div>
      );
    });
  } else {
    description = project.description;
  }

  if (Array.isArray(project.authors)) {
    authors = project.authors.map((el) => {
      return <div>{state.autors[el-1]}</div>;
    });
  } else {
    authors = "";
  }

  return (
    <div id={`project${idx}`} className={styles.ProjectAlt}>
      {/* <div className={`${styles["aside-left"]} ${styles.aside}`}>
        <div className={styles.space}></div>
        <div>ALL PROJECTS</div>
      </div> */}
      <div className={styles.container}>
        <div className={styles['aside-info-title']} >
          <div className={styles['aside-title-container-fixed-height']} ref={aside_title_height}>
            <div id={'aside-title-container-var-size-' + idx} className={styles['aside-title-container-var-size']}>
              <span style={{display: project.title? 'flex' : 'none'}} onClick={() => handleInfoShow(false)} onMouseEnter={handleMouseEnter2} onMouseLeave={handleMouseLeave2}>
                <div style={{
                  width: `29px`,
                  borderTop: `solid 2px #e83c30`,
                }}></div>
                <div style={{
                  width: `8px`,
                  height: `8px`,
                  borderTop: `solid 2px #e83c30`,
                  borderRight: `solid 2px #e83c30`,
                  transform: `rotate(45deg)`,
                  position: `relative`,
                  top: `-2.4px`,
                  left: `-8px`,
                }}></div>
              </span>
              <div className={styles['aside-title']} onClick={() => handleInfoShow(false)} onMouseEnter={handleMouseEnter2} onMouseLeave={handleMouseLeave2}><p>{project.title}</p></div>
            </div></div>
        </div>
        <div className={styles.info}>
          <div id={'titolo-' + idx} className={styles.titolo}>
            <div id={'text-title-' + idx} className={styles['text-title']}>{project.title}</div>
          </div>
          <div className={`${styles["description-container"]}`}>
            <div
              className={`${styles.description} ${styles["info-text"]}`}
              onScroll={onDCScroll}
            >
              {project.description}
            </div>
          </div>
          <div className={styles.autors}>{authors}</div>
          <div className={`${styles["info-text"]} ${styles.description}`}>
            {description}
            <br/><br/><br/>
          </div>
          
        </div>
        <div className={styles["img-container"]} style={{paddingLeft: img_pad_left}}>
          <div style={{width: `100%`, height: `100%`}}>
          <div className={styles["img-wrapper"]}>
            {photos && photos.length>0 ?
            <AdvancedImage
              alt=""
              className={styles.img}
              style={imgStyle}
              cldImg={cld.image(photos[imgIdx].cloudinary)}
            /> 
            : ''}
          </div>
          <div className={styles["handle-container-row-cursor"]}>
            <div
              onMouseEnter={() => handleMouseEnter("sx")}
              onMouseLeave={() => handleMouseLeave("sx")}
              onClick={() => changeImage(-1)}
            ></div>
            <div></div>
            <div
              onMouseEnter={() => handleMouseEnter("dx")}
              onMouseLeave={() => handleMouseLeave("dx")}
              onClick={() => changeImage(+1)}
            ></div>
          </div>
          </div>
        </div>
        <div className={styles.caption}>
          <div className={`${styles["img-caption"]}`}>
            <div
              className={`${styles["img-caption-text"]} ${styles["info-text"]}`}
            >
              {photos && photos.length>0 ? photos[imgIdx].title : ''}
            </div>
            <div
              className={`${styles["img-caption-number"]} ${styles["info-text"]}`}
            >
            </div>
          </div>
          <div style={{flex:1, display:"flex", justifyContent: "center"}}><div>{photos && photos.length>0 ? photos[imgIdx].caption : ''}</div></div>
          
          
        </div>
      </div>
      {/* <div className={`${styles["aside-right"]} ${styles.aside}`}>
        <div>COMMERCIAL</div>
        <div>EDITORIAL</div>
        <div className={styles.space}></div>
      </div> */}
    </div>
  );
}

export default ProjectAlt;
