import Projects, { loader as projectsLoader } from "../Pages/Projects";
// import ProjectAlt, { loader as projectsLoader } from "../Pages/ProjectAlt";
import FrontPage from "./FrontPage";
import HomePage from "./HomePage";
import ProjectsView from "./ProjectsView";
import Prova from "../Prove/Prova";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { useStateValue } from "../State/state";
import MainCMS from "../CMS/MainCMS";
import ProjectCMS from "../CMS/ProjectCMS";
import BioCMS from "../CMS/BioCMS";
import ContactCMS from "../CMS/ContactCMS";
import ProjectsCMS from "../CMS/ProjectsCMS";
import ServerError from "../Pages/ServerError";
import LoginCMS from "../CMS/loginCMS";
import Contacts from "../Pages/Contacts";
import Author from "../Pages/Author";
import Aboutus from "../Pages/Aboutus";

export default function RouteTree() {
  const [state, dispatch] = useStateValue();
  const router = createBrowserRouter([
    {
      path: "/",
      element: <FrontPage />,
    },
    {
      path: "/home",
      element: <HomePage />,
    },

    {
      path: "commercial",
      element: <ProjectsView category="c" />,
    },
    {
      path: "editorial",
      element: <ProjectsView category="e" />,
    },
    {
      path: "commercial/:projectId",
      element: <Projects category="c" />,
    },
    {
      path: "editorial/:projectId",
      element: <Projects category="e" />,
    },
    {
      path:"aboutus",
      element: <Aboutus/>
    },
    {
      path:"aboutus/:author",
      element: <Author/>
    },
    {
      path: "contacts",
      element: <Contacts/>
    },
    {
      path: "admin",
      element: <MainCMS/>,
      children: [
        {
          path:"login/",
          element: <LoginCMS/>
        },
        {
          path: "projects/",
          element: <ProjectsCMS/>,
        },
        {
          path: "projects/:id",
          element: <ProjectCMS/>
        },
        {
          path: "bio/:id",
          element: <BioCMS/>,
        },
        {
          path: "contacts",
          element: <ContactCMS/>
        },
        {
          path: "404",
          element: <ServerError/>,
        }        
      ]
    },
    {
      path: "/404",
      element: <ServerError/>,
    }
  ]);

  return (
    // <div>
    //     <Routes>
    //         <Route path="/" element = {<FrontPage/>}/>
    //         <Route path="/home" element = {<HomePage/>}/>
    //         <Route path="/projects" element = {<ProjectsView/>} />
    //         <Route path="/projects/:projectId" element = {<Projects/>} loader={projectsLoader}/>
    //     </Routes>
    // </div>
    <RouterProvider router={router} />
  );
}
