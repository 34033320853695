const initialState = {
  server_url : 'https://simosechi.pythonanywhere.com/api', //'http://localhost:8000/api', 
  count: 0,
  mouseX: -20,
  mouseY: -20,
  window_width: 1,
  window_height: 1,
  currCategory: "commercial",
  autors: ["Alessandro De Bellis", "Luca Farinet", "Matteo Montenero"],
  author_info: [
    {
      name:"Alessandro De Bellis",
      path:"alessandrodebellis",
      id : 1,
      description : "Alessandro De Bellis is a young Italian photographer from the visual class at the IED in Turin. In 2019 he attended the ICP visual storytelling masterclass and in 2023 he was selected by the Canon student Development Program. Divided between authorial and commercial photography, he is interested in social issues and people. Among the various publications in 2022, his work was exhibited at the Royal Museums of Turin.",
      email : "debellis@refestudio.com",
      instagram: "https://www.instagram.com/alessandro_debellis",
      partitaiva: "12233445566",
      picture: "/Img/Other/ale.jpeg",    
    },
    {
      name: "Luca Farinet",
      path:"lucafarinet",
      id : 2,
      description : "Luca Farinet was born in Aosta on 30 April 1997, he currently lives and works in Turin. In 2021, after a degree in Visual Arts at the European Institute of Design, he founded the REFE collective with two colleagues. He then began working as a photographer, alternating commissions with personal research. Particularly attracted by the world of art and technology, he works as a freelancer with publishing houses, artists, galleries, companies and private individuals.",
      email : "farinet@refestudio.com",
      instagram: "https://www.instagram.com/lucafarinet",
      partitaiva: "12233445566",
      picture: "/Img/Other/luca.jpeg",
    },
    // {
    //   name: "Matteo Montenero",
    //   path: "matteomontenero",
    //   id : 3, 
    //   description : "Photographer and visual storyteller born in 1995, he studied photography at the European Institute of Design in Turin. His images are the result of research that focuses mainly on social issues. He has exhibited in various Italian museums including Triennale Milano, Scuderie del Quirinale and Musei Reali di Torino. In 2020 he won the Canon Young Photographers Student Award.",
    //   email : "montenero@refestudio.com",
    //   instagram: "https://www.instagram.com/matteomontenero",
    //   partitaiva: "12233445566",
    //   picture: "/Img/Other/matte.jpeg",
    // },
  ],
  images: {
    icon:{
      visibility: "/Img/Icon/visibility.png",
      visibility_off: "/Img/Icon/visibility_off.png",
      visibility_svg: "/Img/Icon/visibility.svg",
      visibility_off_svg: "/Img/Icon/visibility_off.svg", 
      position: "/Img/Icon/position.jpg",
      eye: "/Img/Icon/eye.jpg",
      instagram: "/Img/Icon/instagram.jpg"
    },
    logo: {
      logo1: "/Img/Logo/logo1.jpg",
      logo2: "/Img/Logo/logo2.jpg",
      logo3: "/Img/Logo/logo3.jpg",
      logo4: "/Img/Logo/logo4.jpg",
      logo5: "/Img/Logo/logo5.jpg",
      logo6: "/Img/Logo/logo6.jpg",
      logo7: "/Img/Logo/logo7.jpg",
      logo6_bianco: "/Img/Logo/logo6_bianco.png",
      logo6_trasparente: "/Img/Logo/logo6_trasparente.png",
      logo6_red: "/Img/Logo/logo6_border_red.png",
      logo_biancoADB: "/Img/Logo/Logo_biancoADBresize.png",
      logo8: "/Img/Logo/logo8.jpg",
      logo9: "/Img/Logo/logo9.jpg",
      logo_black: "/Img/Logo/logo_nero_trasparente.png"
    },
    photos: {
      0: { url: "/Img/Photo/photo1.jpg", caption: "CAPTION 1" },
      1: { url: "/Img/Photo/photo2.jpg", caption: "CAPTION 2" },
      2: { url: "/Img/Photo/photo3.jpg", caption: "CAPTION 3" },
      3: { url: "/Img/Photo/photo4.jpg", caption: "CAPTION 4" },
      4: { url: "/Img/Photo/photo5.jpg", caption: "CAPTION 5" },
      5: { url: "/Img/Photo/photo6.jpg", caption: "CAPTION 6" },
      6: { url: "/Img/Photo/photo7.jpg", caption: "CAPTION 7" },
      7: { url: "/Img/Photo/photo8.jpg", caption: "CAPTION 8" },
      8: { url: "/Img/Photo/photo9.jpg", caption: "CAPTION 9" },
      9: { url: "/Img/Photo/photo10.jpg", caption: "CAPTION 10" },
      10: { url: "/Img/Photo/photo11.jpg", caption: "CAPTION 11" },
      11: { url: "/Img/Photo/photo12.jpg", caption: "CAPTION 12" },
      12: { url: "/Img/Photo/photo13.jpg", caption: "CAPTION 13" },
      13: { url: "/Img/Photo/photo14.jpg", caption: "CAPTION 14" },
      14: { url: "/Img/Photo/photo15.jpg", caption: "CAPTION 15" },
      15: { url: "/Img/Photo/photo16.jpg", caption: "CAPTION 16" },
      16: { url: "/Img/Photo/photo17.jpg", caption: "CAPTION 17" },
      17: { url: "/Img/Photo/photo18.jpg", caption: "CAPTION 18" },
      18: { url: "/Img/Photo/photo19.jpg", caption: "CAPTION 19" },
      19: { url: "/Img/Photo/photo20.jpg", caption: "CAPTION 20" },
      20: { url: "/Img/Photo/photo21.jpg", caption: "CAPTION 21" },
      21: { url: "/Img/Photo/photo22.jpg", caption: "CAPTION 22" },
      22: { url: "/Img/Photo/photo23.jpg", caption: "CAPTION 23" },
      23: { url: "/Img/Photo/photo24.jpg", caption: "CAPTION 24" },
      24: { url: "/Img/Photo/photo25.jpg", caption: "CAPTION 25" },
      25: { url: "/Img/Photo/photo26.jpg", caption: "CAPTION 26" },
      26: { url: "/Img/Photo/photo27.jpg", caption: "CAPTION 27" },
      // EDITORIAL : NOT MY CUP OF TEA
      27: {
        url: "/Img/Photo/not_my_cup_of_tea/LONDON1.jpg",
        title: "PECKHAM RYE",
        caption:
          "Peckham Rye, in the London Borough of Southwark, is one of the capital’s most multicultural areas. Most of the immigrants here come from former British colonies, such as Nigeria and Jamaica.",
      },
      28: {
        url: "/Img/Photo/not_my_cup_of_tea/LONDON2.jpg",
        title: "",
        caption: "",
      },
      29: {
        url: "/Img/Photo/not_my_cup_of_tea/LONDON3.jpg",
        title: "SHERAZ MILBORY, 28 | PECKHAM",
        caption:
          "“I’m from Iraq but I’ve been living in this country for 15 years. Now I think everything is really getting worse. It's really bad because you can see the government doesn't care about normal people and how we survive. Look around you. Nobody has money. People are poor now. This country has never been like this. I don't care about the monarchy because they only care about rich people. But now that the Queen passed away, things can only get worse.”",
      },
      30: {
        url: "/Img/Photo/not_my_cup_of_tea/LONDON4.jpg",
        title: "REGENT STREET",
        caption:
          "British flags are everywhere, especially since the Queen passed away. But most of the people here are tourists from all over the world and it’s difficult to find a proper Londoner in this area. The Crown Estate owns the entire street and the buildings. In 2021 the Sovereign grant from the Parliament was £103 million, while approximately £10,000 are paid in property taxes.",
      },
      31: {
        url: "/Img/Photo/not_my_cup_of_tea/LONDON5.jpg",
        title: "MEGAN, 18 | PECKHAM",
        caption:
          "“I'm currently studying Fine Art in Camberwell and I'm into art and photography. I'm like against it because I know so little about it. I think the Queen didn’t choose to be the Queen, she grew up under lots of pressure, but I think she could have done more during her time.”",
      },
      32: {
        url: "/Img/Photo/not_my_cup_of_tea/LONDON6.jpg",
        title: "ALEX, 39 | PECKHAM",
        caption:
          "“I'm originally from Oxford and I'm a historian and a YouTuber. I run my own political podcast. For me it’s time for the monarchy to go. It seems a bit backwards to set up a leader who's chosen by inheritance. Would you have surgery performed on you by a surgeon just because his/her dad or his/her mum was a good surgeon? That's just silly. One of them has been taken to court for sexual assault. They don’t pay taxes. They seem to be above the law. People says Charles is good because he cares about climate change, but then he flies all around the world with his own private jet and that’s the equivalent of the pollution caused by 4,500 cars in a whole year.”",
      },
      33: {
        url: "/Img/Photo/not_my_cup_of_tea/LONDON7.jpg",
        title: "PECKHAM RYE",
        caption:
          "Peckham is a very green area, but it isn’t as well looked after as the centre of London.",
      },
      34: {
        url: "/Img/Photo/not_my_cup_of_tea/LONDON8.jpg",
        title: "NICO P., 27 | HACKNEY FLOWER MARKET",
        caption:
          "“I believe in a Republic. OK, I just think that the monarchy has done some pretty shit things that they should acknowledge and that they don't acknowledge, and they should let all the countries that are part of the Commonwealth do their own thing for a while.”",
      },
      35: {
        url: "/Img/Photo/not_my_cup_of_tea/LONDON9.jpg",
        title: "HACKNEY FLOWER MARKET",
        caption:
          "The flower market is now in a mainstream area, but it is still beautiful. Londoners love plants and flowers. In 2018, according to data from the BFA, the British Florist Association, the sales of flowers were worth £1.3 billion. The British, in paying their respects to the Queen, spent £500 million on flowers, 80% of which came from the Netherlands",
      },
      36: {
        url: "/Img/Photo/not_my_cup_of_tea/LONDON10.jpg",
        title: "OXFORD STREET",
        caption:
          "After more than 70 years, the era of Queen Elizabeth II is over. The Royal Family has a diplomatic and not a legislative role, which belongs to the Parliament. However, their media influence is so powerful that the monarchy's brand contributes £2.5 billion a year to the British economy.",
      },
      37: {
        url: "/Img/Photo/not_my_cup_of_tea/LONDON11.jpg",
        title: "JOHN DIAZ, 21, AND CYNTHIA, 20 | PECKHAM",
        caption:
          "John: “I'm studying sculpture and I'm also a full-time carer. My relationship with the monarchy is that I have respect for them from an outside point of view, being that I was born in a foreign country which wasn't in the Commonwealth, the Philippines. I respect their role, but I disagree on some aspects, including the controversy regarding figures like Megan and Princess Diana”. Cinthya: “As a second-generation immigrant, I don't have anything against them, but I do feel like the monarchy is out of date, old-fashioned and doesn't fit our society entirely and where we're moving. There are also cases of racism in the Royal Family and I cannot support that.”",
      },
      38: {
        url: "/Img/Photo/not_my_cup_of_tea/LONDON12.jpg",
        title: "NOLWENN DOUGLAS, 17 | PECKHAM RYE",
        caption:
          "“I study Photography in Brighton. I honestly don't have a lot of opinions on the monarchy, but I think it's a relatively corrupt institution. It seems to be quite a spectacle and I find it a bit dramatic, how much we big up the Queen and Prince William and Princess Kate. I think it's a lot for show more than it is funny. It's not a democratic institution. It's not like we vote our Queen. It's just people are put into power when they have no real background in politics or anything, which I find slightly unfair and kind of outdated.”",
      },
      39: {
        url: "/Img/Photo/not_my_cup_of_tea/LONDON13.jpg",
        title: "PECKHAM RYE",
        caption:
          "The “Shard“ skyscraper (in the background), one of the tallest in Europe, is a long way from here, as are the tourists. It seems that the more second-generation British immigrants there are in one area, the more the support for the British Crown fades.",
      },
      40: {
        url: "/Img/Photo/not_my_cup_of_tea/LONDON14.jpg",
        title: "PECKHAM RYE",
        caption:
          "This is a genuinely multicultural area where it’s possible to see a lot of different people and outfits. Traditions and cultures from all over the world meet here in London.",
      },
      41: {
        url: "/Img/Photo/not_my_cup_of_tea/LONDON15.jpg",
        title: "MICAH, 44 | PECKHAM",
        caption:
          "“I'm originally from the Caribbean and I've been residing in England now for about 30 years. To be honest, I'm not even sure what they actually do, or how we’re supposed to really know what we see in the media because we're not sure what goes on behind the scenes. They show us what we want to know. What is the truth about what's actually happening outside? I mean, we hear rumours, we had different stories and, to me, they're just like the rest of us. But they have to modernize! They are back from when? The 1500s? It is 2022 and they should bring the monarchy into modernity to help the future generation deal with future problems.”",
      },
      42: {
        url: "/Img/Photo/not_my_cup_of_tea/LONDON16.jpg",
        title: "JAKE BAILEY, 19 | TOWER BRIDGE",
        caption:
          "“I work as a medic for a private ambulance company. I'm a strong supporter of the monarchy in England and there's a lot of great things for us as a country. I think without them the country would be in a really bad place. I think a lot of people feel really patriotic about the Queen, about the Royal Family and about the monarchy. So, I think obviously that the Queen passing is a devastating thing, but I think we'll get up and we'll move on. I think things will definitely change with Charles.”",
      },
      43: {
        url: "/Img/Photo/not_my_cup_of_tea/LONDON17.jpg",
        title: "SAM LANGDALE, 22 | THE BARBICAN CENTRE",
        caption:
          "“I’m from Southampton and I'm a music producer. I’ve just finished university. So as far as the monarchy goes, I feel like I don't actively support them in any way, but I think it's important to understand or respect their role in British culture and the shaping of it. Whether it's positive or negative, I think it's important to understand how it has shaped our culture, but, of course, I don't like the colonialist past and they definitely need a big shift if they want to fit the change of prospective of the youth.”",
      },
      44: {
        url: "/Img/Photo/not_my_cup_of_tea/LONDON18.jpg",
        title: "SOUTHWARK",
        caption:
          "The “Shard” skyscraper is massive and it is astonishing to look at it as it is near older British houses: the past and the future. Although the view from the top is spectacular, lots of apartments are still vacant with prices ranging from £30 million to £50 million.",
      },
      45: {
        url: "/Img/Photo/not_my_cup_of_tea/LONDON19.jpg",
        title: "ANDREW, 33 | THE MALL",
        caption:
          "“I’m from Northampton and I am not really into the monarchy or the Royal Family, but I am more into the Conservative politics of the Tories and I strongly support Brexit. I like the fact that they don’t have any practical power in our parliament.”",
      },
      46: {
        url: "/Img/Photo/not_my_cup_of_tea/LONDON20.jpg",
        title: "KENDRA HASSAN, 21 | 123 OLD KENT ROAD",
        caption:
          "“I am a marketing consultant but in my spare time I love to do Contemporary Art. Do I support the monarchy? No, and the reason is because of the history of the British Empire and their impact on the Commonwealth and how they have been involved in colonialism and slavery. I am a second-generation black Nigerian woman and I live in the UK. I've definitely seen how the monarchy has affected my loved ones and my family. My grandpa moved here in the 1960s and that was about the time when Nigeria got independence, but the devastation that the British monarchy had perpetrated on Nigeria and its culture is irreparable at this point.”",
      },
      47: {
        url: "/Img/Photo/not_my_cup_of_tea/LONDON21.jpg",
        title: "HACKNEY",
        caption:
          "Pubs in the UK are a symbol, they play an important role in British culture and are places where people can gather and party. The patriotism expressed with flags is evident in this pub in Hackney. The Union Jack represents the British Empire, an empire that, after Brexit and the death of Queen Elizabeth, looks increasingly distant from ancient times.",
      },
      48: {
        url: "/Img/Photo/not_my_cup_of_tea/LONDON22.jpg",
        title: "DANI, 22 | PECKHAM",
        caption:
          "“I'm an art student at the moment. I'm not a fan of the monarchy personally. I think it's a waste of money, taxpayers’ money and I just don’t think it’s democratic. I don't really agree. That is a concept and I think it should be abolished. I don't really get this kind of idolization of the monarchy in the 21st century. I think it's a bit weird. I just think it's like kind of gross wealth. That's unnecessary, really.”",
      },
      49: {
        url: "/Img/Photo/not_my_cup_of_tea/LONDON23.jpg",
        title: "PECKHAM RYE",
        caption:
          "The shops in the area mainly sell second-hand electronic devices or else they are exotic food markets. On these streets, in order to earn some money, many women of African descent without a steady job offer to cut or style the hair of passers-by.",
      },
      50: {
        url: "/Img/Photo/not_my_cup_of_tea/LONDON24.jpg",
        title: "REN JAMES, 32 | SOHO",
        caption:
          "I guess the state of the monarchy is interesting right now. We haven't seen any major changes in our history, but we don't know what could happen. The King is now in place, the Queen is gone. I believe they're still elements in Great Britain. We’ve still got safety. We’ve still got health. I've been around to other places and I have to say I'm quite grateful that I’ve got a British passport. I can definitely say that. You know, a lot of people try to say the good times are gone, but I think that's not necessarily true. The good times should be ahead.",
      },
      51: {
        url: "/Img/Photo/not_my_cup_of_tea/LONDON25.jpg",
        title: "PECKHAM RYE",
        caption:
          "It seems difficult to imagine the splendour and the immensity of the Royal Palaces from this street, where the buildings are covered in graffiti and are in poor condition.",
      },
      
      52:{
        url: "/Img/Photo/not_my_cup_of_tea/provacopertina.jpg"
      }, 
      
      
      //COMMERCIAL : ATP 2021
      
      53:{
        url: "/Img/Photo/commercial/ATP/ALEXANDER ZVEREV ATP FINALS 2021 ATP FINALS 2021-3.jpg",
        title: "",
        caption:"Alexander Zverev",
      },
      
      54:{}, //vuota
      55:{}, //vuota

      56:{
        url: "/Img/Photo/commercial/ATP/MatteO Berrettini ATP Finals 2021.jpg",
        title: "",
        caption:"Matteo Berrettini",
      },
      57:{
        url: "/Img/Photo/commercial/ATP/Novak Djokovic ATP FINALS 2021.jpg",
        title: "",
        caption:"Novak Djokovic",
      },
      58: {
        url: "/Img/Photo/commercial/ATP/Yannick Sinner ATP Finals 2021.jpg",
        title: "",
        caption:"Yannick Sinner",
      },
      // COMMERCIAL: BAUTISTA COVER SPOTIFY
      59: {
        url: "/Img/Photo/commercial/Bautista Cover/BAUTISTA_COVERSPOTIFY-4.jpg",
        title: "",
        caption:"",
      },
      60: {
        url: "/Img/Photo/commercial/Bautista Cover/bautista_small1.jpg",
        title: "",
        caption:"",
      },
      61: {
        url: "/Img/Photo/commercial/Bautista Cover/BAUTISTA_COVERSPOTIFY2.jpg",
        title: "",
        caption:"",
      },
      62: {
        url: "/Img/Photo/commercial/Bautista Cover/bautista_small2.jpg",
        title: "",
        caption:"",
      },
      // COMMERCIAL: CARLOTTA BERTOTTI
      63: {
        url: "/Img/Photo/commercial/Carlotta Bertotti/CarlottaBertotti.jpg",
        title: "",
        caption:"",
      },
      64: {
        url: "/Img/Photo/commercial/Carlotta Bertotti/CarlottaBertotti2.jpg",
        title: "",
        caption:"",
      },
      65: {
        url: "/Img/Photo/commercial/Carlotta Bertotti/CarlottaBertotti3.jpg",
        title: "",
        caption:"",
      },
      // COMMERCIAL: CASSIOPEA
      66: {
        url: "/Img/Photo/commercial/Cassiopea/stillLife_Cassiopea1.jpg",
        title: "",
        caption:"stillLife Cassiopea",
      },
      67: {
        url: "/Img/Photo/commercial/Cassiopea/stillLife_Cassiopea2.jpg",
        title: "",
        caption:"stillLife Cassiopea",
      },
      // COMMERCIAL : DON ALBERTO
      68: {
        url: "/Img/Photo/commercial/Don Alberto/E5CBAE34-932C-4044-A5AA-308885E2FE32.jpg",
        title: "",
        caption:"",
      },
      69: {
        url: "/Img/Photo/commercial/Don Alberto/REFESTUDIO_RITRATTIDONALBERTO-4.jpg",
        title: "",
        caption:"ritratto Don Alberto",
      },
      // COOMERCIAL : FIT OUT LOBBY
      70: {
        url: "/Img/Photo/commercial/Fit Out Lobby/Fit Out Lobby- Viale Certosa n2 Milano- by GestProject.jpg",
        title: "",
        caption:"",
      },
      71: {
        url: "/Img/Photo/commercial/Fit Out Lobby/Fit Out Lobby- Viale Certosa n2 Milano- by GestProject2.jpg",
        title: "",
        caption:"",
      },
      72: {
        url: "/Img/Photo/commercial/Fit Out Lobby/Fit Out Lobby- Viale Certosa n2 Milano- by GestProject3.jpg",
        title: "",
        caption:"",
      },
      // COMMERCIAL : GRAN TEATRO LA FENICE
      73: {
        url: "/Img/Photo/commercial/Gran Teatro/Gran Teatro La Fenice - Scenografia Dutch National Opera - Venezia2022.jpg",
        title: "",
        caption:"Scenografia Dutch National Opera",
      },
      74: {
        url: "/Img/Photo/commercial/Gran Teatro/Gran Teatro La Fenice - Venezia2022.jpg",
        title: "",
        caption:"",
      },
      //COMMERCIAL : LAVAZZA
      75: {
        url: "/Img/Photo/commercial/Lavazza/Lavazza1895_2.jpg",
        title: "",
        caption:"",
      },
      76: {
        url: "/Img/Photo/commercial/Lavazza/Lavazza1895.jpg",
        title: "",
        caption:"",
      },
      // COMMERCIAL : MAGGIO
      77: {
        url: "/Img/Photo/commercial/Maggio/MAGGIO-iDmagazineIT.jpg",
        title: "",
        caption:"",
      },
      78: {
        url: "/Img/Photo/commercial/Maggio/MAGGIO-iDmagazineIT2.jpg",
        title: "",
        caption:"",
      },
      79: {
        url: "/Img/Photo/commercial/Maggio/MAGGIO-iDmagazineIT3.jpg",
        title: "",
        caption:"",
      },
      // COMMERCIAL : LAVORO SENZA NOME
      80: {
        url: "/Img/Photo/commercial/modifica/DeBellis_210101_5037-Modifica.jpg",
        title: "",
        caption:"",
      },
      81: {
        url: "/Img/Photo/commercial/modifica/DeBellis_210101_5304-Modifica.jpg",
        title: "",
        caption:"",
      },
      82: {
        url: "/Img/Photo/commercial/modifica/DeBellis_210101_5343-Modifica.jpg",
        title: "",
        caption:"",
      },
      // COMMERCIAL : PIRELLI
      83: {
        url: "/Img/Photo/commercial/Pirelli/Miky Biasion.jpg",
        title: "",
        caption:"Miky Biasion",
      },
      84: {
        url: "/Img/Photo/commercial/Pirelli/Pirelli-Scorpion 2022 .jpg",
        title: "",
        caption:"Pirelli Scorpion 20222",
      },
      85: {
        url: "/Img/Photo/commercial/Pirelli/PIRELLI-scorpion-LAPPONIA2022_2.jpg",
        title: "",
        caption:"PIRELLI scorpion LAPPONIA 2022",
      },
      86: {
        url: "/Img/Photo/commercial/Pirelli/PIRELLI-scorpion-LAPPONIA2022.jpg",
        title: "",
        caption:"PIRELLI scorpion LAPPONIA 2022",
      },
      // COMMERCIAL : TRULY DESIGN
      87: {
        url: "/Img/Photo/commercial/Truly Design/TrulyDesign-OramgePalestre_Torino 2023_2.jpg",
        title: "",
        caption:"",
      },
      88: {
        url: "/Img/Photo/commercial/Truly Design/TrulyDesign-OramgePalestre_Torino 2023_3.jpg",
        title: "",
        caption:"",
      },

      89: {
        url: "/Img/Photo/commercial/Truly Design/TrulyDesign-OramgePalestre_Torino 2023.jpg",
        title: "",
        caption:"",
      },
      90: {
        url: "/Img/Photo/commercial/ATP/ATP_SITO1.JPG",
        title: "",
        caption:"Alexander Zverev",
      },
      91: {
        url: "/Img/Photo/commercial/ATP/ATP_SITO2.JPG",
        title: "",
        caption:"Novak Djokovic",
      },
      92: {
        url: "/Img/Photo/commercial/ATP/ATP_SITO3.JPG",
        title: "",
        caption:"",
      },
      93: {
        url: "/Img/Photo/commercial/ATP/ATP_SITO4.JPG",
        title: "",
        caption:"",
      },
      94: {
        url: "/Img/Photo/commercial/ATP/ATP_SITO5.JPG",
        title: "",
        caption:"Alexander Zverev",
      },
      95: {
        url: "/Img/Photo/commercial/ATP/ATP_SITO6.JPG",
        title: "",
        caption:"Alexander Zverev",
      },
      96: {
        url: "/Img/Photo/commercial/ATP/ATP_SITO7.JPG",
        title: "",
        caption:"Casper Ruud",
      },
      97: {
        url: "/Img/Photo/commercial/ATP/ATP_SITO8.JPG",
        title: "",
        caption:"Yannick Sinner",
      },
      98: {
        url: "/Img/Photo/commercial/ATP/ATP_SITO9.JPG",
        title: "",
        caption:"Yannick Sinner",
      },
      99: {
        url: "/Img/Photo/commercial/ATP/ATP_SITO10.JPG",
        title: "",
        caption:"Stefanos Tsitsipas",
      },
      100: {
        url: "/Img/Photo/commercial/ATP/ATP_SITO11.JPG",
        title: "",
        caption:"",
      },
      101: {
        url: "/Img/Photo/commercial/ATP/ATP_SITO12.JPG",
        title: "",
        caption:"Zlatan Ibrahimović - Novak Djokovic",
      },
      102: {
        url: "/Img/Photo/commercial/ATP/ATP_SITO13.JPG",
        title: "",
        caption:"Zlatan Ibrahimović - Novak Djokovic",
      },
    },
    other: {
      profile: {
        alessandro: "/Img/Other/ale.jpeg",
        luca: "/Img/Other/luca.jpeg",
        matteo: "/Img/Other/matte.jpeg",
        description_alessandro: "Alessandro De Bellis è un giovane fotografo italiano classe visive allo IED di Torino. Nel 2019 frequenta la masterclass di visual storytelling di ICP e nel 2023 viene selezionato dal Canon student Development Program. Diviso tra fotografia autoscale e commerciale, è interessato a tematiche sociali e alle persone. Tra le varie pubblicazioni nel 2022 il suo lavoro è stato esposto ai Musei Reali di Torino."
      },
    },
  },
  projects: [
    {
      id: 1,
      title: "NOT MY CUP OF TEA",
      autors: [1, 3],
      description: [
        "WHAT YOUNG LONDONERS THINK ABOUT THE MONARCHY",
        "Since the Queen's death, many people have wondered about the future of the British monarchy. Thanks to a combination of scandals and TV series, the Royal Family’s image seems more like that of a movie than real life.",
        "The monarchy appears to be dated in the eyes of young people, many of whom are second generation immigrants. They are no longer able to ignore Britain’s colonial past and the racial and criminal scandals involving some members of the Royal Family. Although the British monarchy has no legislative powers, it still has a tremendous impact on the country's economy and generates an estimated £2.5 billion in revenue. We began this photographic project in order to get away from the image of the Royal Family that’s created by the media. Instead, we try and listen to – and tell – the stories and opinions of Londoners.",
      ],
      photos: [
        27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45,
        46, 47, 48, 49, 50, 51,
      ],
      cover: 52,
      coverOrientation: "vertical",
      category: "editorial",
    },
    {
      id: 2,
      title: "portrait",
      autors: [],
      description: '',
      photos: [53, 56, 57, 58, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102],
      cover: 53,
      coverOrientation: "orizontal",
      category: "commercial",
    },
    {
      id: 3,
      title: "still life",
      autors: [],
      description:
        "",
      photos: [53],
      cover: 53,
      coverOrientation: "orizontal",
      category: "commercial",
    },
    {
      id: 4,
      title: "architecture",
      description:
        "",
      photos: [63, 64, 65],
      cover: 53,
      coverOrientation: "orizontal",
      category: "commercial",
    },
    {
      id: 5,
      title: "STUDIO CASSIOPEA",
      description:"",
      photos: [66, 67],
      coverOrientation: "orizontal",
      category: "commercial",
    },
    {
      id: 6,
      title: "DON ALBERTO",
      description:
        "",
      photos: [68, 69],
      coverOrientation: "orizontal",
      category: "commercial",
    },
    {
      id: 7,
      title: "FIT OUT LOBBY - MILANO",
      description:
        "by GestProject",
      photos: [70, 71, 72],
      coverOrientation: "vertical",
      category: "commercial",
    },
    {
      id: 8,
      title: "GRAN TEATRO LA FENICE",
      description:
        "Venezia",
      photos: [73, 74],
      coverOrientation: "orizontal",
      category: "commercial",
    },
    {
      id: 9,
      title: "LAVAZZA 1895",
      description:
        ["by Truly Design"], 
      photos: [75, 76],
      coverOrientation: "orizontal",
      category: "commercial",
    },
    {
      id: 10,
      title: "MAGGIO",
      description:
        "song writer",
      photos: [77, 78, 79],
      coverOrientation: "vertical",
      category: "commercial",
    },
    {
      id: 11,
      title: "",
      description:
        "",
      photos: [80, 81, 82],
      coverOrientation: "orizontal",
      category: "commercial",
    },
    {
      id: 12,
      title: "STUDIO PIRELLI",
      description:
        "",
      photos: [83, 84, 85, 86],
      coverOrientation: "vertical",
      category: "commercial",
    },
    {
      id: 13,
      title: "ORANGE PALESTRE - TRULY DESIGN",
      description:
        "Torino 2023",
      photos: [87, 88, 89],
      coverOrientation: "orizontal",
      category: "commercial",
    },
    {
      id: 14,
      title: "ATP FINALS 2021 - TORINO",
      autors: [],
      description: '',
      photos: [53, 56, 57, 58, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102],
      cover: 56,
      coverOrientation: "vertical",
      category: "commercial",
    },
    {
      id: 15,
      title: "BAUTISTA COVER SPOTIFY",
      autors: [],
      description:
        "",
      photos: [59, 60 , 61 ,62],
      cover: 62,
      coverOrientation: "vertical",
      category: "commercial",
    },
    {
      id: 16,
      title: "CARLOTTA BERTOTTI",
      description:
        "",
      photos: [63, 64, 65],
      cover: 64,
      coverOrientation: "orizontal",
      category: "commercial",
    },
  ],
};

export default initialState;
