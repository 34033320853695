import styles from "./projectsView.module.css";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import { useStateValue } from "../State/state";
import {Cloudinary} from '@cloudinary/url-gen'
import {AdvancedImage} from '@cloudinary/react'
import {
  changeMouseX,
  changeMouseY,
  setCurrentCategory,
} from "../State/action";

export default function ProjectsView({ category }) {
  const [state, dispatch] = useStateValue();

  const navigate = useNavigate();
  const [centerClip, setCenterClip] = useState({ x: 0, y: 0 });
  const [projects, setProjects] = useState();
  const left = state.mouseX - 10;
  const top = state.mouseY - 10;

  const cld = new Cloudinary({
    cloud:{
        cloudName: 'refestudio',
    }
  })



  /////// FETCH PROJECTS FROM SERVER ////////

  // save into projects state

  useEffect(()=>{
    fetch(state.server_url + '/projects/?visible=True',{
    })
    .then((res)=>{
        if(res.status === 401) navigate('../login')
        return res.json()
    })
    .then((data)=>{
        setProjects(data.projects.filter(e => e.category === category));
    }).catch((e)=>console.log(e))
  }, [category]);

  useEffect(() => {
    window.addEventListener("mousemove", customMouseMove);
    return () => window.removeEventListener("mousemove", customMouseMove);
  }, []);

  function customMouseMove(e) {
    dispatch(changeMouseX(e.clientX));
    dispatch(changeMouseY(e.clientY));
  }

  return (
    <div
      className={styles.projectsView}
      
      // onMouseMove={e=>{
      //     dispatch(changeMouseX(e.pageX));
      //     dispatch(changeMouseY(e.pageY));
      //     document.querySelector(`.${styles.mouse}`).style.position='absolute';

      // }}
      onMouseLeave={() => {
        dispatch(changeMouseX(-20));
        dispatch(changeMouseY(-20));
      }}
    >
      <div className={styles.header}></div>
      <div className={styles.container_projects}>
        {projects ? projects.map((project, projectIdx) => {
          const data = {
            projectIdx: projectIdx,
          };
          if (project.coverOrientation === "vertical")
            return (
              <div key={projectIdx} className={styles.project_double}>
                <Link to={`${project.id}`} state={{ data: data }}>
                  {project.cover? <AdvancedImage
                  style={{
                    width: 100 + "%",
                    height: 95 + "%",
                    objectFit: "cover",
                  }}
                  cldImg={cld.image(project.cover)}
                  alt={project.title}
                  />:
                  <img
                    style={{
                      width: 100 + "%",
                      height: 95 + "%",
                      objectFit: "cover",
                    }}
                    src={state.images.logo.logo5}
                    alt={project.title}
                  />}
                </Link>
                <div className={styles.title}>{project.title}</div>
              </div>
            );
          else if (project.coverOrientation === "orizontal")
            return (
              <div key={projectIdx} className={styles.project}>
                <Link to={`${project.id}`} state={{ data: data }}>
                  {project.cover? <AdvancedImage
                  style={{
                    width: 100 + "%",
                    height: 95 + "%",
                    objectFit: "cover",
                  }}
                  cldImg={cld.image(project.cover)}
                  alt={project.title}
                  />:
                  <img
                    style={{
                      width: 100 + "%",
                      height: 95 + "%",
                      objectFit: "cover",
                    }}
                    src={state.images.logo.logo5}
                    alt={project.title}
                  />}
                </Link>
                <div className={styles.title}>{project.title}</div>
              </div>
            );
          else
            return (
              <div key={projectIdx} className={styles.project}>
                <Link to={`${project.id}`} state={{ data: data }}>
                  {project.cover? <AdvancedImage
                  style={{
                    width: 100 + "%",
                    height: 95 + "%",
                    objectFit: "cover",
                  }}
                  cldImg={cld.image(project.cover)}
                  alt={project.title}
                  />:
                  <img
                    style={{
                      width: 100 + "%",
                      height: 95 + "%",
                      objectFit: "cover",
                    }}
                    src={state.images.logo.logo5}
                    alt={project.title}
                  />}
                </Link>
                <div className={styles.title}>{project.title}</div>
              </div>
            );
        }): <div style={{alignSelf: 'center', textAlign: 'center', width:'100%'}}>loading projects...</div>}
      </div>
      <div className={styles.footer}><div>&copy; 2024 Refe Studio - All rights reserved </div></div>
      <div id={styles.logoProjectsView}>
        <img
          style={{ height: `100%` }}
          id={styles.logo6}
          src={state.images.logo.logo1}
          alt=""
          onClick={() => navigate("/home")}
        />
      </div>
      <div
        className={styles.mouse}
        style={{ left: left + "px", top: top + "px" }}
      ></div>
      <div className={styles.buttonContainer}>
        <div
          className={styles.button}
          style={{
            textDecorationLine:
              category === "c" ? "underline" : "none",
          }}
          onClick={() => {
            dispatch(setCurrentCategory("commercial"));
            navigate("/commercial");
          }}
        >
          COMMERCIAL
        </div>
        <div
          className={styles.button}
          style={{
            textDecorationLine: category === "e" ? "underline" : "none",
          }}
          onClick={() => {
            dispatch(setCurrentCategory("editorial"));
            navigate("/editorial");
          }}
        >
          EDITORIAL
        </div>
      </div>

      <div
        className={styles.logoBack}
        onMouseMove={(e) => {
          setCenterClip({ x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY });
          document.querySelector(
            `.${styles.mouse}`
          ).style.transform = `scale(1.5)`;
        }}
        onMouseLeave={() => {
          setCenterClip({ x: 0, y: 0 });
          document.querySelector(
            `.${styles.mouse}`
          ).style.transform = `scale(1)`;
        }}
        onClick={() => navigate("/home")}
      >
        <div
          style={{
            width: 60.59 + "px",
            height: 48 + "px",
            padding: 15 + "px",
            backgroundImage: `url(${state.images.logo.logo4})`,
            backgroundPosition: `15px 15px`,
            backgroundSize: `calc(100% - 30px)`,
            backgroundRepeat: "no-repeat",
            WebkitClipPath: `circle( 15px at ${centerClip.x}px ${centerClip.y}px)`,
            clipPath: `circle( 15px at ${centerClip.x}px ${centerClip.y}px)`,
          }}
        ></div>
        {/* <img style={{width: 100+'%'}} src={state.images.logo.logo4} alt=''/> */}
      </div>
    </div>
  );
}
