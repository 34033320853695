import { useStateValue } from "../State/state";
import styles from "./bioAndInfo.module.css";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { changeMouseX, changeMouseY } from "../State/action";
import { red } from "@cloudinary/url-gen/actions/adjust";



export default function Aboutus() {

    const [state, dispatch] = useStateValue();
    const [centerClip, setCenterClip] = useState({ x: 0, y: 0 });
    const navigate = useNavigate();
    const left = state.mouseX - 10;
    const top = state.mouseY - 10;

    useEffect(() => {
        window.addEventListener("mousemove", customMouseMove);
        return () => window.removeEventListener("mousemove", customMouseMove);
    }, []);

    function customMouseMove(e) {
        dispatch(changeMouseX(e.clientX));
        dispatch(changeMouseY(e.clientY));
    }

    return (
        <div className={styles.container}

            onMouseLeave={() => {
                dispatch(changeMouseX(-20));
                dispatch(changeMouseY(-20));
            }}
        >
            {/* <div className={styles.section}>WHAT ABOUT REFE</div> */}
            <div className={styles.intro}>REFE is a photographic collective based in Turin</div>
            <div className={styles.section}>KNOW US BETTER</div>
            <div className={styles.profile_container}>
                <div onClick={()=>navigate(state.author_info[0].path)}>
                    <img className={styles.profile_image} 
                    src={state.images.other.profile.alessandro}/>
                    <p >Alessandro De Bellis</p>
                </div>
                {/* <div onClick={()=>navigate(state.author_info[2].path)}>
                    <img className={styles.profile_image} 
                    src={state.images.other.profile.matteo}/>
                    <p >Matteo Montenero</p>
                </div> */}
                <div onClick={()=>navigate(state.author_info[1].path)}>
                    <img className={styles.profile_image} 
                    src={state.images.other.profile.luca}/>
                    <p >Luca Farinet</p>
                    
                </div>
            </div>
            <div>
                <div>Logo made by <a href={'https://truly-design.com/'} style={{color:'#e83c30'}}>Truly Design</a></div>
                <div>Website made by <a href={'https://www.linkedin.com/in/simone-s-b78933290/'} style={{color:'#e83c30'}}>Simone Sechi</a></div>
            </div>

            <div className={styles.header}></div>
            <div id={styles.logoBioAndInfo}>
                <img
                    style={{ height: `100%` }}
                    id={styles.logo6}
                    src={state.images.logo.logo1}
                    alt=""
                    onClick={() => navigate("/home")}
                />
            </div>
            <div
                className={styles.mouse}
                style={{ left: left + "px", top: top + "px" }}
            ></div>

            <div id={styles.footer}>&copy; 2024 Refe Studio - All rights reserved</div>

            <div
                className={styles.logoBack}
                onMouseMove={(e) => {
                    setCenterClip({ x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY });
                    document.querySelector(
                        `.${styles.mouse}`
                    ).style.transform = `scale(1.5)`;
                }}
                onMouseLeave={() => {
                    setCenterClip({ x: 0, y: 0 });
                    document.querySelector(
                        `.${styles.mouse}`
                    ).style.transform = `scale(1)`;
                }}
                onClick={() => navigate("/home")}
            >
                <div
                    style={{
                        width: 60.59 + "px",
                        height: 48 + "px",
                        padding: 15 + "px",
                        backgroundImage: `url(${state.images.logo.logo4})`,
                        backgroundPosition: `15px 15px`,
                        backgroundSize: `calc(100% - 30px)`,
                        backgroundRepeat: "no-repeat",
                        WebkitClipPath: `circle( 15px at ${centerClip.x}px ${centerClip.y}px)`,
                        clipPath: `circle( 15px at ${centerClip.x}px ${centerClip.y}px)`,
                    }}
                ></div>
            </div>
        </div>

    )
}
