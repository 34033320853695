export default function ServerError(){
    return(
        <div style={{
            display:"flex",
            flexDirection:"column",
            justifyContent:"center",
            alignItems:"center",
            height: "100vh",
        }}>
            <h1>ERROR!</h1>
            <p>Please check if the url is right</p>            
        </div>
    )
}